import React from 'react';

/**
 * Gets a components properties, regardless if it is a React component or an DOM element
 */
export type ApparentComponentProps<
  TComponent extends
    | keyof JSX.IntrinsicElements
    | React.JSXElementConstructor<any>
> = TComponent extends React.JSXElementConstructor<infer P>
  ? JSX.LibraryManagedAttributes<TComponent, P>
  : React.ComponentPropsWithoutRef<TComponent>;

export const isDOMTypeElement = (element: React.ElementType) => {
  return typeof element === 'string';
};

export const isFunction = (
  functionToCheck: unknown,
): functionToCheck is Function => typeof functionToCheck === 'function';
