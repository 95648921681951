import React from 'react';
import { Box, PolymorphicComponentProps } from 'react-polymorphic-box';
import classNames from 'classnames';
import type {
  TextSize,
  Weight,
  FontColor,
} from '@messagebird/plume-design-tokens';
import './text.css';

export interface OwnTextProps {
  /** The contents of the `Text` */
  children?: React.ReactNode;

  className?: string | undefined;

  /**
   * Text text size, pick aliased value
   *
   * Find scale at https://plume.messagebird.com/?path=/docs/design-tokens-font-size--page
   */
  size?: TextSize;

  /** Font weight, like bold, normal, thin. */
  weight?: Weight;

  /** Font color, pick an alias value. */
  color?: FontColor;
}

export type TextProps<
  Element extends React.ElementType = React.ElementType
> = PolymorphicComponentProps<
  Element,
  OwnTextProps & {
    /**
     * Element used to render the item in the DOM. Use any semantic element or component that fits your needs.
     *
     * @default 'span'
     */
    as?: Element;
  }
>;

/**
 * Style text the way you want.
 */
export const Text = ({
  children,
  size = 'base',
  weight = 'normal',
  color = 'base',
  className,
  ...props
}: TextProps) => {
  return (
    <Box
      as="span"
      {...props}
      className={classNames(
        'plm-u-text',
        `plm-u-text__size-${size}`,
        `plm-u-text__weight-${weight}`,
        `plm-u-text__color-${color}`,
        className,
      )}
    >
      {children}
    </Box>
  );
};
