import { useButton } from '@react-aria/button';
import classNames from 'classnames';
import mergeProps from 'merge-props';
import React, { FunctionComponent, ReactNode, useRef } from 'react';
import { VisuallyHidden } from '../../foundation';
import { XIcon } from '../../icons';
import { useFocusVisible } from '../../utils/useFocusVisible';

type PressEvent = Parameters<typeof useButton>[0]['onPress'];

export type CloseButtonProps = {
  /**
   * Handler that is called when the close button is pressed
   */
  onClose: PressEvent;
  /**
   * Text used to identify the close button.
   */
  children: ReactNode;

  /**
   * Whether the close button should receive focus on render.
   */
  autoFocus?: boolean;

  /**
   * This is to overwrite the default behaviour in focus handling
   *
   * **!!DON'T USE THIS PROPERTY!!** Documentation/Demonstration use only.
   */
  closeFocusVisible?: boolean;
};

/**
 * Notifications tell users when something happened in the application. They display important information that users should to acknowledge but is not blocking them.
 */
export const CloseButton: FunctionComponent<CloseButtonProps> = ({
  onClose,
  autoFocus,
  children,
  closeFocusVisible,
}) => {
  const { focusProps, isFocusVisible } = useFocusVisible({
    autoFocus,
    UNSAFE__focusVisible: closeFocusVisible,
  });

  const closeButtonRef = useRef<HTMLButtonElement>(null);
  const { buttonProps } = useButton(
    { autoFocus, onPress: onClose },
    closeButtonRef,
  );

  return (
    <button
      className={classNames('plm-c-notification--close', {
        'plm-c-notification--close__focused': isFocusVisible,
      })}
      ref={closeButtonRef}
      {...mergeProps(focusProps, buttonProps)}
    >
      <VisuallyHidden>{children}</VisuallyHidden>
      <XIcon aria-hidden className="plm-c-notification--close--icon" />
    </button>
  );
};
