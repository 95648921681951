import React from 'react';
import classNames from 'classnames';
import { FocusScope } from '@react-aria/focus';
import { useLabel } from '@react-aria/label';
import mergeProps from 'merge-props';
import { Stack, StackItem } from '../../foundation/layout/Stack';
import { Label } from '../Label';
import './button-group.css';

type AccessibilityProps = {
  /** The element's unique identifier. See [MDN](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/id).*/
  id?: string;
  /** Defines a string value that labels the current element.*/
  'aria-label'?: string;
  /** Identifies the element (or elements) that labels the current element.*/
  'aria-labelledby'?: string;
  /** Identifies the element (or elements) that describes the object.*/
  'aria-describedby'?: string;
  /** Identifies the element (or elements) that provide a detailed, extended description for the object.*/
  'aria-details'?: string;
};

export type ButtonGroupProps = {
  /** The Button(s) contained within the ButtonGroup. */
  children: React.ReactNode;

  /** The content to display as the label. */
  label?: React.ReactNode;

  /**
   * ARIA role for the group.
   * @default "toolbar"
   */
  role?: string;

  className?: string;
} & AccessibilityProps;

/**
 * ButtonGroup are visually much like SegmentedControl, but used when no active
 * state can be provided. They mimic as a toolbar.
 *
 * The ButtonGroup will group the buttons visually and semantically.
 */
function ButtonGroup({ role = 'toolbar', ...props }: ButtonGroupProps) {
  const { fieldProps, labelProps } = useLabel(props);

  return (
    <Stack orientation="vertical" gap="2">
      {props.label && (
        <StackItem>
          <Label as="span" {...labelProps}>
            {props.label}
          </Label>
        </StackItem>
      )}

      <StackItem>
        <FocusScope>
          <div
            {...mergeProps(props, fieldProps)}
            role={role}
            className={classNames('plm-c-button-group', props.className)}
          >
            {props.children}
          </div>
        </FocusScope>
      </StackItem>
    </Stack>
  );
}

export { ButtonGroup };
