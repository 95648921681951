import { useButton } from '@react-aria/button';
import { useId } from '@react-aria/utils';
import { useFocusRing } from '@react-aria/focus';
import {
  useFocusWithin,
  useHover,
  useKeyboard,
} from '@react-aria/interactions';
import { useOverlayPosition } from '@react-aria/overlays';
import mergeProps from 'merge-props';
import React, { useMemo, useRef } from 'react';
import { usePortalContainer } from '../../contexts/Portal';
import { ToggletipState } from './useToggletipState';
import { getPxFromRem } from '../../utils/getPxFromRem';
import { useCSSCustomProperties } from '../../utils/useCSSCustomProperties';
import { assignRef } from '../../utils/assignRef';

export const useInteractiveToggletip = (
  props: { isFocusVisible?: boolean; toggletipId?: string },
  state: ToggletipState,
) => {
  const overlayId = useId(props.toggletipId);
  const { hoverProps } = useHover({
    onHoverStart: state.open,
    onHoverEnd: state.close,
  });

  const { focusWithinProps } = useFocusWithin({
    onBlurWithin: state.closeWithPrio,
  });

  const { focusProps, isFocusVisible } = useFocusRing({
    within: true,
  });

  const triggerRef = React.useRef<HTMLButtonElement>(null);
  const [
    {
      '--plm-dt-toggletip-bubble-spacing': toggletipBubbleSpacing,
      '--plm-dt-size-base': base,
    },
    ref,
  ] = useCSSCustomProperties(
    '--plm-dt-toggletip-bubble-spacing',
    '--plm-dt-size-base',
  );
  const offset = getPxFromRem(toggletipBubbleSpacing, base);

  const { buttonProps } = useButton(
    {
      onPress: state.toggleWithPrio,
    },
    triggerRef,
  );

  const { keyboardProps } = useKeyboard({
    onKeyUp: (e) => {
      if (e.key === 'Escape' && state.isOpen === true) {
        state.closeWithPrio();
      } else {
        // continuePropagation is needed to allow `useButton` to catch keyboard events. By default, they are not propagated with `useKeyboard`
        e.continuePropagation();
      }
    },
  });

  const overlayRefObject = useRef<HTMLDivElement | null>(null);
  const overlayRef = useMemo(() => assignRef(ref, overlayRefObject), [ref]);

  const portalContainer = usePortalContainer();
  const {
    overlayProps,
    /** value is initially empty until rendered the first time, then updated */
    placement = 'top',
  } = useOverlayPosition({
    targetRef: triggerRef,
    overlayRef: overlayRefObject,
    placement: 'top left',
    offset,
    isOpen: state.isOpen,
    boundaryElement: portalContainer,
  });

  return {
    isFocusVisible: props.isFocusVisible ?? isFocusVisible,
    containerProps: mergeProps(focusProps, focusWithinProps, keyboardProps),
    triggerProps: mergeProps(buttonProps, hoverProps, {
      'aria-describedby': overlayId,
    }),
    overlayProps: mergeProps(overlayProps, { id: overlayId }),
    triggerRef,
    placement,
    overlayRef,
  };
};
