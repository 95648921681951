import React from 'react';
import mergeProps from 'merge-props';
import { useToggleState } from '@react-stately/toggle';
import { useToggle } from '@react-aria/toggle';
import classNames from 'classnames';
import {
  useFocusVisible,
  FocusVisibleProps,
} from '../../utils/useFocusVisible';
import { assignRef } from '../../utils/assignRef';
import { useIsHovered } from '../../utils/useIsHovered';
import './toggle.css';
import { Stack, StackItem, Text } from '../../foundation';

type FocusProps = {
  /**
   * Whether the element should receive focus on render.
   * @default false
   */
  autoFocus?: boolean;
  /** Handler that is called when the element receives focus. */
  onFocus?: (e: React.FocusEvent) => void;
  /** Handler that is called when the element loses focus. */
  onBlur?: (e: React.FocusEvent) => void;
  /** Handler that is called when the element's focus status changes. */
  onFocusChange?: (isFocused: boolean) => void;
};

type InputToggleProps = {
  /**
   * Whether the element should be selected (uncontrolled).
   */
  defaultSelected?: boolean;
  /**
   * Whether the element should be selected (controlled).
   */
  isSelected?: boolean;
  /**
   * Handler that is called when the element's selection state changes.
   */
  onChange?: (isSelected: boolean) => void;
  /**
   * The value of the input element, used when submitting an HTML form. See [MDN](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdefvalue).
   */
  value?: string;
  /**
   * The name of the input element, used when submitting an HTML form. See [MDN](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdefname).
   */
  name?: string;
  /**
   * The element's unique identifier. See [MDN](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/id).
   */
  id?: string;

  /** Whether the input is disabled. */
  isDisabled?: boolean;
  /** Whether the input can be selected but not changed by the user. */
  isReadOnly?: boolean;
};

type AriaProps = {
  /**
   * Identifies the element (or elements) whose contents or presence are controlled by the current element.
   */
  'aria-controls'?: string;
  /**
   * Defines a string value that labels the current element.
   */
  'aria-label'?: string;
  /**
   * Identifies the element (or elements) that labels the current element.
   */
  'aria-labelledby'?: string;

  /**
   * Identifies the element (or elements) that describes the object.
   */
  'aria-describedby'?: string;

  /**
   * Identifies the element (or elements) that provide a detailed, extended description for the object.
   */
  'aria-details'?: string;
  // https://www.w3.org/TR/wai-aria-1.2/#aria-errormessage
  /**
   * Identifies the element that provides an error message for the object.
   */
  'aria-errormessage'?: string;

  /**
   * Whether to exclude the element from the sequential tab order. If true,
   * the element will not be focusable via the keyboard by tabbing. This should
   * be avoided except in rare scenarios where an alternative means of accessing
   * the element or its functionality via the keyboard is available.
   */
  excludeFromTabOrder?: boolean;
};

type KeyboardEventsProps = {
  /** Handler that is called when a key is pressed. */
  onKeyDown?: (e: React.KeyboardEvent) => void;
  /** Handler that is called when a key is released. */
  onKeyUp?: (e: React.KeyboardEvent) => void;
};

type LabelProps = {
  /**
   * The label for the element.
   */
  children?: React.ReactNode;
};

export type ToggleProps = LabelProps &
  InputToggleProps &
  FocusProps &
  KeyboardEventsProps &
  AriaProps &
  FocusVisibleProps;

function Toggle(
  props: ToggleProps,
  externalRef:
    | ((instance: HTMLInputElement | null) => void)
    | React.MutableRefObject<HTMLInputElement | null>
    | null,
) {
  const state = useToggleState(props);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const { isFocusVisible, focusProps } = useFocusVisible(props);
  const { isHovered, hoverProps } = useIsHovered(props);
  const { inputProps } = useToggle(props, state, inputRef);

  return (
    <Stack
      as="label"
      {...mergeProps(hoverProps, {
        className: classNames(
          'plm-c-toggle',
          props.isDisabled && 'plm-c-toggle--disabled',
        ),
        htmlFor: inputProps.id,
      })}
      orientation="horizontal"
      gap="2"
    >
      <StackItem>
        <input
          {...mergeProps(focusProps, inputProps, {
            className: 'plm-u-visually-hidden',
          })}
          ref={assignRef(inputRef, externalRef)}
        />

        <div
          className={classNames('plm-c-toggle__lever', {
            'plm-c-toggle__lever--hovered': isHovered,
            'plm-c-toggle__lever--selected': state.isSelected,
            'plm-c-toggle__lever--unselected': !state.isSelected,
            'plm-c-toggle__lever--disabled': props.isDisabled,
            'plm-c-toggle__lever--focused': isFocusVisible,
          })}
        />
      </StackItem>

      {props.children && (
        <StackItem as={Text} color="primary" size="sm">
          {props.children}
        </StackItem>
      )}
    </Stack>
  );
}

const _Toggle = React.forwardRef<HTMLInputElement, ToggleProps>(Toggle);
export { _Toggle as Toggle };

Toggle.displayName = 'Plume__Toggle';
