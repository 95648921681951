import React, { Fragment, FunctionComponent } from 'react';

interface EyeIconProps {
  isOpen: boolean;
}

const EyeIcon: FunctionComponent<EyeIconProps> = ({ isOpen }) => {
  return (
    <Fragment>
      <svg width={20} height={14}>
        <g
          fill="none"
          fillRule="evenodd"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        >
          {isOpen ? (
            <Fragment>
              <path d="M1.6 9.2h0a10 10 0 0116.8 0h0M10 4.6V1.5M6.8 5.1L5.3 2.4M3.7 6.7L1 5.1m12.2 0l1.5-2.7m1.6 4.3L19 5.1" />
              <path d="M13.4 8c0 2-1.5 3.5-3.4 3.5A3.5 3.5 0 016.6 8c0-2 1.5-3.5 3.4-3.5s3.4 1.6 3.4 3.5z" />
            </Fragment>
          ) : (
            <Fragment>
              <path d="M1.6 5.2h0a10 10 0 0016.8 0h0M10 9.8v3M6.8 9.2l-1.5 2.7M3.7 7.6L1 9.2m12.2 0l1.5 2.7m1.6-4.3L19 9.2" />
            </Fragment>
          )}
        </g>
      </svg>
    </Fragment>
  );
};

export default EyeIcon;
