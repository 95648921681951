import React from 'react';
import { useRadio } from '@react-aria/radio';
import classNames from 'classnames';
import mergeProps from 'merge-props';
import { NestingError } from '../../../errors/nesting-error';
import { Stack, StackItem } from '../../../foundation/layout/Stack';
import { assignRef } from '../../../utils/assignRef';
import {
  FocusVisibleProps,
  useFocusVisible,
} from '../../../utils/useFocusVisible';
import { RadioContext } from '../RadioGroup';
import './radio-input.css';
import { Text } from '../../../foundation';

type AccessibilityProps = {
  /** The element's unique identifier. See [MDN](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/id). */
  id?: string;
  /** Defines a string value that labels the current element. */
  'aria-label'?: string;
  /** Identifies the element (or elements) that labels the current element. */
  'aria-labelledby'?: string;
  /** Identifies the element (or elements) that describes the object. */
  'aria-describedby'?: string;
  /** Identifies the element (or elements) that provide a detailed, extended description for the object. */
  'aria-details'?: string;
};

type EventProps = {
  /**	Handler that is called when the element receives focus. */
  onFocus?: (e: React.FocusEvent) => void;
  /**	Handler that is called when the element loses focus. */
  onBlur?: (e: React.FocusEvent) => void;
  /**	Handler that is called when the element's focus status changes. */
  onFocusChange?: (isFocused: boolean) => void;
  /**	Handler that is called when a key is pressed. */
  onKeyDown?: (e: React.KeyboardEvent) => void;
  /**	Handler that is called when a key is released. */
  onKeyUp?: (e: React.KeyboardEvent) => void;
};

export type RadioInputProps = {
  /** The label for the Radio. Accepts any renderable node. */
  children?: React.ReactNode;
  /**
   * The value of the radio button, used when submitting an HTML form.
   * See [MDN](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/radio#Value).
   */
  value: string;

  className?: string;

  /** Whether the radio button is disabled or not. Shows that a selection exists, but is not available in that circumstance. */
  isDisabled?: boolean;
} & FocusVisibleProps &
  EventProps &
  AccessibilityProps;

function RadioInput(props: RadioInputProps, ref: React.Ref<HTMLInputElement>) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const { children, className, isDisabled } = props;
  const { focusProps, isFocusVisible } = useFocusVisible(props);
  const state = React.useContext(RadioContext);

  if (state === null) {
    throw new NestingError(
      'RadioInput',
      `The RadioInput component is used without a RadioGroup around. The RadioGroup is needed to manage the state a set of radio inputs.`,
      `<RadioGroup>
    <RadioInput value="apple">Apples</RadioInput>
</RadioGroup>`,
    );
  }

  const { inputProps } = useRadio(props, state, inputRef);

  return (
    <StackItem
      as="label"
      className={classNames('plm-c-radio-input', className, {
        'plm-c-radio-input--disabled': isDisabled,
        'plm-c-radio-input--unselected': state.selectedValue !== props.value,
        'plm-c-radio-input--selected': state.selectedValue === props.value,
        'plm-c-radio-input--focused': isFocusVisible,
      })}
    >
      <Stack orientation="horizontal" gap="2">
        <StackItem>
          <input
            {...mergeProps(inputProps, focusProps, {
              className: 'plm-u-visually-hidden',
            })}
            ref={assignRef(inputRef, ref)}
          />

          <div className="plm-c-radio-input__lever" />
        </StackItem>

        {children && (
          <StackItem as={Text} color="primary" size="sm">
            {children}
          </StackItem>
        )}
      </Stack>
    </StackItem>
  );
}

RadioInput.displayName = 'Plume__RadioInput';

const _RadioInput = React.forwardRef(RadioInput);

export { _RadioInput as RadioInput };
