import { useFocusRing } from '@react-aria/focus';

export type FocusVisibleProps = {
  /**
   * This is to overwrite the default behaviour in focus handling
   *
   * **!!DON'T USE THIS PROPERTY!!** Documentation/Demonstration use only.
   */
  UNSAFE__focusVisible?: boolean;

  /**
   * Whether the element should receive focus on render.
   */
  autoFocus?: boolean;
};

export function useFocusVisible({
  autoFocus,
  UNSAFE__focusVisible: enforceFocusVisible,
}: FocusVisibleProps) {
  const { isFocusVisible, focusProps } = useFocusRing({ autoFocus });
  const isFocusVisibleWithProps =
    typeof enforceFocusVisible === 'boolean'
      ? enforceFocusVisible
      : isFocusVisible;

  return { isFocusVisible: isFocusVisibleWithProps, focusProps };
}
