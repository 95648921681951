import cc from '@messagebird/componentized-classnames';
import type { Spacing } from '@messagebird/plume-design-tokens';
import { Orientation } from '../../../utils/orientation';
import './stack.css';

export type StackJustify =
  | 'start'
  | 'end'
  | 'center'
  | 'space-around'
  | 'space-between';
export type StackAlign = 'top' | 'middle' | 'bottom';

export interface StackProps {
  /**
   *  gap between the items
   * @default "4"
   */
  gap?: Spacing;

  /**
   * @default 'start'
   */
  justify?: StackJustify | undefined;

  /**
   * @default "top"
   */
  align?: StackAlign | undefined;

  /**
   * @default 'vertical'
   */
  orientation?: Orientation;
}

export interface StackCustomProperties {
  '--gap': string;
}

export const Stack = cc<StackProps, StackCustomProperties>('div', {
  displayName: 'Plume__Stack',
})({
  classNames: [
    'plm-l-stack',
    (props) => ({
      'plm-l-stack--vertical':
        props.orientation === undefined || props.orientation === 'vertical',
      'plm-l-stack--horizontal': props.orientation === 'horizontal',
      'plm-l-stack--justify-start': props.justify === 'start',
      'plm-l-stack--justify-end': props.justify === 'end',
      'plm-l-stack--justify-center': props.justify === 'center',
      'plm-l-stack--justify-space-around': props.justify === 'space-around',
      'plm-l-stack--justify-space-between': props.justify === 'space-between',
      'plm-l-stack--align-top': props.align === 'top',
      'plm-l-stack--align-middle': props.align === 'middle',
      'plm-l-stack--align-bottom': props.align === 'bottom',
    }),
  ],
  style: (props) =>
    props.gap !== undefined
      ? {
          '--gap': `var(--plm-dt-size-spacing-${props.gap})`,
        }
      : undefined,
});

export interface StackItemProps {
  split?: boolean;
}

export const StackItem = cc<StackItemProps>('div', {
  displayName: 'Plume__StackItem',
})({
  classNames: [
    'plm-l-stack__item',
    (props) => ({
      'plm-l-stack__item--split': props.split === true,
    }),
  ],
});
