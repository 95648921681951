import { MutableRefObject } from 'react';

/**
 * Use to assign a value to any reference type in the React ecosystem.
 * Supports MutableRefObjects (the ones hooks generate) and assing functions (widely used with the DOM)
 */
export const assignRef = <T>(
  ...refs: Array<
    ((instance: T | null) => void) | MutableRefObject<T | null> | null
  >
) => (element: T) => {
  refs.forEach((ref) => {
    if (typeof ref === 'function') {
      ref(element);
    } else if (ref && typeof ref === 'object') {
      (ref as React.MutableRefObject<T>).current = element;
    }
  });
};
