import React from 'react';

export const XIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={8} height={8} viewBox="0 0 8 8" fill="none" {...props}>
    <path
      d="M1 7l6-6M7 7L1 1"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const FilledXIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
    <circle r={8} cy={8} cx={8} fill="var(--plm-dt-color-red-70)" />
    <path
      d="M5 11l6-6M11 11L5 5"
      stroke="var(--plm-dt-color-white)"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
