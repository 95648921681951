import React from 'react';

export type Icon = 'search';

export interface AppendIconProps {
  icon: Icon;
}

const AppendIcon = ({ icon }: AppendIconProps) => {
  switch (icon) {
    case 'search':
      return (
        <svg width={16} height={16}>
          <path
            fillRule="evenodd"
            d="M12 10.7l3.7 3.8a.9.9 0 01-1.2 1.2L10.7 12a6.7 6.7 0 111.3-1.3zm-.4-4a4.9 4.9 0 10-9.8 0 4.9 4.9 0 009.8 0z"
            fill="currentColor"
          />
        </svg>
      );
  }
};

export default AppendIcon;
