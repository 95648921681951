import React from 'react';
import { useRadio } from '@react-aria/radio';
import classNames from 'classnames';
import mergeProps from 'merge-props';
import { NestingError } from '../../errors/nesting-error';
import { assignRef } from '../../utils/assignRef';
import {
  FocusVisibleProps,
  useFocusVisible,
} from '../../utils/useFocusVisible';
import { SegmentedControlContext } from './Group';
import '../Button/button.css';
import { Button } from '../Button';

type AccessibilityProps = {
  /** The element's unique identifier. See [MDN](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/id). */
  id?: string;
  /** Defines a string value that labels the current element. */
  'aria-label'?: string;
  /** Identifies the element (or elements) that labels the current element. */
  'aria-labelledby'?: string;
  /** Identifies the element (or elements) that describes the object. */
  'aria-describedby'?: string;
  /** Identifies the element (or elements) that provide a detailed, extended description for the object. */
  'aria-details'?: string;
};

type EventProps = {
  /**	Handler that is called when the element receives focus. */
  onFocus?: (e: React.FocusEvent) => void;
  /**	Handler that is called when the element loses focus. */
  onBlur?: (e: React.FocusEvent) => void;
  /**	Handler that is called when the element's focus status changes. */
  onFocusChange?: (isFocused: boolean) => void;
  /**	Handler that is called when a key is pressed. */
  onKeyDown?: (e: React.KeyboardEvent) => void;
  /**	Handler that is called when a key is released. */
  onKeyUp?: (e: React.KeyboardEvent) => void;
};

export type SegmentedControlProps = {
  /** The label for the Radio. Accepts any renderable node. */
  children?: React.ReactNode;
  /**
   * The value of the radio button, used when submitting an HTML form.
   * See [MDN](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/radio#Value).
   */
  value: string;

  className?: string;

  /** Whether the radio button is disabled or not. Shows that a selection exists, but is not available in that circumstance. */
  isDisabled?: boolean;
} & FocusVisibleProps &
  EventProps &
  AccessibilityProps;

function SegmentedControl(
  props: SegmentedControlProps,
  ref: React.Ref<HTMLInputElement>,
) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const { children, className } = props;

  const { focusProps, isFocusVisible } = useFocusVisible(props);
  const state = React.useContext(SegmentedControlContext);

  if (state === null) {
    throw new NestingError(
      'SegmentedControl',
      `The SegmentedControl component is used without a SegmentedControlGroup around. The SegmentedControl is needed to manage the state of a set of controls.`,
      `<SegmentedControlsGroup>
    <SegmentedControl value="apple">Apples</RadioInput>
</SegmentedControlsGroup>`,
    );
  }

  const { inputProps } = useRadio(props, state, inputRef);

  return (
    <Button
      as="label"
      className={classNames(className, {
        'plm-c-button--pressed': state.selectedValue === props.value,
      })}
      {...mergeProps(props, focusProps)}
      variant="secondary"
      // remove the default "button" role
      role=""
      UNSAFE__focusVisible={isFocusVisible}
    >
      <input
        {...mergeProps(inputProps, {
          className: 'plm-u-visually-hidden',
        })}
        ref={assignRef(inputRef, ref)}
      />

      {children}
    </Button>
  );
}

SegmentedControl.displayName = 'Plume__SegmentedControl';

const _SegmentedControl = React.forwardRef(SegmentedControl);

export { _SegmentedControl as SegmentedControl };
