import React from 'react';
import { useHover } from '@react-aria/interactions';

export type HoverProps = {
  isDisabled?: boolean;
};

export function useIsHovered({ isDisabled }: HoverProps) {
  let [isHovered, setHovered] = React.useState(false);
  let { hoverProps } = useHover({
    isDisabled,
    onHoverChange: setHovered,
  });
  return { hoverProps, isHovered };
}
