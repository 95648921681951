import classNames from 'classnames';
import React from 'react';
import mergeProps from 'merge-props';
import { Portal } from '../../contexts/Portal';
import { VisuallyHidden } from '../../foundation/VisuallyHidden';
import { useInteractiveToggletip } from './useInteractiveToggletip';
import { useToggletipState } from './useToggletipState';
import './toggletip.css';

export type ToggletipProps = {
  /** Body of the toggletip bubble when open. Keep it short but descriptive. */
  children: React.ReactNode;
  /**
   * Content of the trigger button. Will be hidden for visual users and
   * visible/read out for users of assistive technology.
   *
   * A good value are localized versions of "more info" or be more descriptive.
   */
  triggerButtonLabel: React.ReactNode;
  /**
   * Sets the CSS [className](https://developer.mozilla.org/en-US/docs/Web/API/Element/className)
   * for the element.
   */
  className?: string;

  /**
   * Sets a width constraint to the bubble and allows for text wrapping
   */
  maxWidth?: string;

  /** id for the toggletip bubble */
  toggletipId?: string;
  /**
   * This is to overwrite the default behaviour for opening/closing
   *
   * **!!DON'T USE THIS PROPERTY!!** Documentation/Demonstration use only.
   */
  UNSAFE__isOpen?: boolean;
  /**
   * This is to overwrite the default behaviour in focus handling
   *
   * **!!DON'T USE THIS PROPERTY!!** Documentation/Demonstration use only.
   */
  UNSAFE__isFocusVisible?: boolean;
};

/**
 * Toggletips allow to show information to the user by request.
 */
function Toggletip(
  props: ToggletipProps,
  ref:
    | ((instance: HTMLDivElement | null) => void)
    | React.MutableRefObject<HTMLDivElement | null>
    | null,
) {
  const state = useToggletipState({
    isOpen: props.UNSAFE__isOpen,
  });

  const {
    isFocusVisible,
    containerProps,
    triggerProps,
    triggerRef,
    overlayProps,
    overlayRef,
    placement,
  } = useInteractiveToggletip(
    {
      isFocusVisible: props.UNSAFE__isFocusVisible,
      toggletipId: props.toggletipId,
    },
    state,
  );

  return (
    <div
      className={classNames('plm-c-toggletip', props.className, {
        'plm-c-toggletip--is-open': state.isOpen,
        'plm-c-toggletip--focused': isFocusVisible,
      })}
      {...containerProps}
      ref={ref}
    >
      <button
        type="button"
        className="plm-c-toggletip__trigger"
        {...triggerProps}
        ref={triggerRef}
      >
        <span aria-hidden>?</span>
        <VisuallyHidden>{props.triggerButtonLabel}</VisuallyHidden>
      </button>

      <Portal>
        <div
          aria-live="polite"
          className={classNames('plm-c-toggletip__bubble', {
            'plm-c-toggletip__bubble--is-open': state.isOpen,
            'plm-c-toggletip__bubble--top': placement === 'top',
            'plm-c-toggletip__bubble--bottom': placement === 'bottom',
            'plm-c-toggletip__bubble--has-max-width': props.maxWidth,
          })}
          {...mergeProps(overlayProps, {
            style: { maxWidth: props.maxWidth },
          })}
          ref={overlayRef}
        >
          <span aria-hidden={!state.isOpen}>{props.children}</span>
        </div>
      </Portal>
    </div>
  );
}

const _Toggletip = React.forwardRef(Toggletip);

export { _Toggletip as Toggletip };
