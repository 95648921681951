import React from 'react';

export const CheckmarkIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={8} height={6} viewBox="0 0 8 6" fill="none" {...props}>
    <path
      d="M7 1L2.973 5 1 2.953"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const FilledCheckmarkIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 8A8 8 0 110 8a8 8 0 0116 0z"
      fill="var(--plm-dt-color-green-60)"
    />
    <path
      d="M11 6l-4 4-2-2"
      stroke="var(--plm-dt-color-white)"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
