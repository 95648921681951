import { useButton } from '@react-aria/button';
import { useFocusRing } from '@react-aria/focus';
import { useHover } from '@react-aria/interactions';
import { useOverlayPosition } from '@react-aria/overlays';
import { useSelect } from '@react-aria/select';
import { SelectState } from '@react-stately/select';
import classNames from 'classnames';
import mergeProps from 'merge-props';
import React, { useRef } from 'react';
import { assignRef } from '../../utils/assignRef';
import { ListBoxPopup, isPlacement } from './ListBoxPopup';

type UseSelectAria = ReturnType<typeof useSelect>;
type SelectAriaProps = Parameters<typeof useSelect>[0];

type SelectAtomProps<T extends unknown> = {
  state: SelectState<T>;
  triggerProps: UseSelectAria['triggerProps'];
  valueProps: UseSelectAria['valueProps'];
  menuProps: UseSelectAria['menuProps'];
  validationState?: SelectAriaProps['validationState'];

  /** Whether the items are currently loading. */
  isLoading?: boolean;
  /** Handler that is called when more items should be loaded, e.g. while scrolling near the bottom. */
  onLoadMore?: () => void;
  /** Whether the input is disabled. */
  isDisabled?: boolean;
  autoFocus?: boolean;

  placeholder: React.ReactNode;
};

const ArrowIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={10} height={6} {...props}>
    <path d="M10 0L5 6 0 0z" fill="currentColor" />
  </svg>
);

export const SelectAtom = React.forwardRef(
  <T extends unknown>(
    props: SelectAtomProps<T>,
    outerRef: React.Ref<HTMLButtonElement>,
  ) => {
    const {
      triggerProps,
      valueProps,
      menuProps,
      validationState,
      state,
      placeholder,
    } = props;

    // Get props for child elements from useSelect
    const innerRef = useRef<HTMLButtonElement>(null);

    let { isFocusVisible, focusProps } = useFocusRing({
      autoFocus: props.autoFocus,
    });

    // Get props for the button based on the trigger props from useSelect
    const { buttonProps } = useButton(triggerProps, innerRef);
    const { hoverProps, isHovered } = useHover({});

    const overlayRef = useRef<HTMLUListElement>(null);
    let { overlayProps: positionProps, placement } = useOverlayPosition({
      targetRef: innerRef,
      overlayRef,
      placement: 'bottom',
      containerPadding: 0,
      offset: 0,
      isOpen: state.isOpen,
    });

    if (!isPlacement(placement)) {
      if (placement !== undefined) {
        console.error(
          `Invalid placement of Select options. Expected 'top' | 'bottom', but got '${placement}'. Falling back to 'bottom'.`,
        );
      }
      placement = 'bottom';
    }

    return (
      <div
        style={{
          position: 'relative',
        }}
        className={classNames('plm-c-select', {
          'plm-c-select__invalid': validationState === 'invalid',
          'plm-c-select__valid': validationState === 'valid',
          'plm-c-select__disabled': props.isDisabled,
        })}
      >
        <button
          {...mergeProps(buttonProps, hoverProps, focusProps)}
          ref={assignRef(outerRef, innerRef)}
          className={classNames('plm-c-select--trigger', {
            'plm-c-select--trigger__hovered': isHovered,
            'plm-c-select--trigger__open': state.isOpen,
            'plm-c-select--trigger__open-top':
              state.isOpen && placement === 'top',
            'plm-c-select--trigger__open-bottom':
              state.isOpen && placement === 'bottom',
            'plm-c-select--trigger__close': !state.isOpen,
            'plm-c-select--trigger__focused': isFocusVisible,
          })}
        >
          <div {...valueProps}>
            {state.selectedItem
              ? state.selectedItem.rendered
              : placeholder && (
                  <span className="plm-c-select--placeholder">
                    {placeholder}
                  </span>
                )}
          </div>

          <ArrowIcon
            aria-hidden="true"
            className={classNames('plm-c-select--arrow-icon', {
              'plm-c-select--arrow-icon__open': state.isOpen,
              'plm-c-select--arrow-icon__hovered': isHovered,
            })}
          />
        </button>
        {state.isOpen && (
          <ListBoxPopup
            {...mergeProps(menuProps, positionProps)}
            ref={overlayRef}
            placement={placement}
            state={state}
            isLoading={props.isLoading}
            onLoadMore={props.onLoadMore}
          />
        )}
      </div>
    );
  },
);

SelectAtom.displayName = 'Plume__Select__SelectAtom';
