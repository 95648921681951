import cc from '@messagebird/componentized-classnames';
import './grid.css';
import type { Spacing } from '@messagebird/plume-design-tokens';

type GridPropsColumnMode = {
  columns?: 1 | 2 | 3 | 4 | 6 | 12;
};

type GridPropsAutoMode = {
  columns?: 'auto-fit' | 'auto-fill';
  /**
   * Minimum width of a single column.
   *
   * css sizing (e.g. 10px, 30rem)
   */
  minimumColumnWidth?: string;
};

export type GridProps = {
  gap?: Spacing;
  columns?: 1 | 2 | 3 | 4 | 6 | 12 | 'auto-fit' | 'auto-fill';
  blueprint?: boolean;
  /**
   * Should it scroll?
   * x = top to bottom,
   * y = left to right,
   * both = x + y
   * false = nope
   */
  scroll?: 'x' | 'y' | 'both' | false;

  /**
   * Applies the same height to every row.
   */
  sameHeight?: boolean;
} & (GridPropsColumnMode | GridPropsAutoMode);

export interface GridCustomProperties {
  '--gap'?: string;
  '--columns'?: string | number;
  '--min-width'?: string;
}

export const Grid = cc<GridProps, GridCustomProperties>('div', {
  displayName: 'Plume__Grid',
})({
  classNames: [
    'plm-l-grid',
    (props) => ({
      'plm-l-grid--blueprint': props.blueprint,
      'plm-l-grid--blueprint-columns':
        props.blueprint && typeof props.columns === 'number',
      'plm-l-grid--scroll-y': props.scroll === 'y' || props.scroll === 'both',
      'plm-l-grid--scroll-x': props.scroll === 'x' || props.scroll === 'both',
    }),
  ],
  style: (props) => ({
    '--columns': props.columns,
    '--gap':
      props.gap !== undefined
        ? `var(--plm-dt-size-spacing-${props.gap})`
        : undefined,
    '--min-width':
      'minimumColumnWidth' in props ? props.minimumColumnWidth : undefined,
    gridAutoRows: props.sameHeight ? '1fr' : undefined,
  }),
});

export interface GridItemProps {
  columnSpan?: number;
  rowSpan?: number;
}

interface GridItemCustomProperties {
  '--column-span'?: number;
  '--row-span'?: number;
}

export const GridItem = cc<GridItemProps, GridItemCustomProperties>('div')({
  className: 'plm-l-grid__item',
  style: (props) => ({
    '--column-span': props.columnSpan,
    '--row-span': props.rowSpan,
  }),
});
