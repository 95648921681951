import { useFocus } from '@react-aria/interactions';
import { useOption } from '@react-aria/listbox';
import { SelectState } from '@react-stately/select';
import { Node } from '@react-types/shared';
import classNames from 'classnames';
import mergeProps from 'merge-props';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { assignRef } from '../../utils/assignRef';

export interface OptionProps<T extends unknown> {
  item: Node<T>;
  state: SelectState<T>;
  onInViewChange?: ((isInView: boolean) => void | null | undefined) | null;
}

export const Option = <T extends unknown>({
  item,
  state,
  onInViewChange,
}: OptionProps<T>) => {
  const innerRef = React.useRef<HTMLLIElement>(null);

  const isDisabled = state.disabledKeys.has(item.key);
  const isSelected = state.selectionManager.isSelected(item.key);
  const { optionProps } = useOption(
    {
      key: item.key,
      isDisabled,
      isSelected,
      shouldSelectOnPressUp: true,
      shouldFocusOnHover: true,
      isVirtualized: true,
    },
    state,
    innerRef,
  );

  const inViewRef = useOnViewChange(onInViewChange);

  // Handle focus events so we can apply highlighted
  // style to the focused option
  const [isFocused, setFocused] = React.useState(false);
  const { focusProps } = useFocus({ onFocusChange: setFocused });
  const className = classNames('plm-c-select--option', {
    'plm-c-select--option__selected': isSelected,
    'plm-c-select--option__focused': isFocused,
  });

  const props = mergeProps(optionProps, focusProps, {
    className,
    children: item.rendered,
  });

  return <li {...props} ref={assignRef(innerRef, inViewRef)} />;
};

const useOnViewChange = (
  onInViewChange: ((inView: boolean) => void) | undefined | null,
) => {
  const { ref, inView } = useInView();

  React.useEffect(() => {
    if (!onInViewChange) {
      return;
    }

    onInViewChange(inView);
  }, [inView, onInViewChange]);

  return ref;
};
