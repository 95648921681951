export class NestingError extends TypeError {
  constructor(
    componentName: string,
    description: string,
    usageExample: string,
  ) {
    const error = super(
      `[Plume ${componentName}]: Invalid Nesting of component ${componentName}
      
      ${description}`,
    );

    console.error(error);
    console.error(`The above error occurred in the ${componentName} component.

What went wrong: ${description}

How to use the component correct: ${usageExample}
`);
  }
}
