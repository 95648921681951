/* eslint-disable jsx-a11y/no-noninteractive-tabindex, jsx-a11y/no-autofocus */

import React, { forwardRef, Ref } from 'react';
import type { Spacing, Dimension } from '@messagebird/plume-design-tokens';
import {
  Box as PolymorphicBox,
  PolymorphicComponentProps,
} from 'react-polymorphic-box';
import classnames from 'classnames';
import mergeProps from 'merge-props';

export type BoxVariant =
  | 'base'
  | 'primary'
  | 'secondary'
  | 'clickable'
  | 'disabled';

export type StyledBoxProps<
  Element extends React.ElementType = React.ElementType
> = PolymorphicComponentProps<
  Element,
  {
    /**
     * The contents of the Box.
     */
    children: React.ReactNode;

    /**
     * Element used to render the item in the DOM. Use any semantic element or component that fits your needs.
     *
     * @default 'div'
     */
    as?: Element;

    /**
     * style variant of the box. If none is selected, no card styling is applied
     */
    variant?: BoxVariant | undefined;

    /**
     * space on the inside. You can specify one value for all sides or differentiate between inline and block values
     *
     * block = in ltr and rtl languages top/bottom
     * inline = in ltr and rtl languages left/right
     */
    padding?:
      | Spacing
      | {
          /** in ltr and rtl languages top/bottom */
          block: Spacing;
          /** in ltr and rtl languages left/right */
          inline: Spacing;
        }
      | undefined
      | null;

    /**
     * Maximal width the box may have.
     *
     * This is the maximal outer size the box may have.
     */
    maxWidth?: Dimension;
  }
>;

export const StyledBox = forwardRef(
  <Element extends React.ElementType>(
    {
      className,
      children,
      autoFocus,
      containFocus,
      restoreFocus,
      tabIndex,
      variant,
      padding,
      maxWidth,
      ...props
    }: StyledBoxProps<Element>,
    ref: Ref<HTMLDivElement>,
  ) => {
    return (
      <PolymorphicBox
        as={'div'}
        {...mergeProps(
          {
            style: { ...getPadding(padding), ...getMaxWidth(maxWidth) },
            className: classnames(
              'plm-l-box',
              variant !== undefined && variantToClassName[variant],

              className,
            ),
          },
          props,
        )}
        tabIndex={tabIndex}
        ref={ref}
      >
        {children}
      </PolymorphicBox>
    );
  },
) as <Element extends React.ElementType>(
  props: StyledBoxProps<Element>,
) => React.ReactElement;

const baseClassName = 'plm-l-box';
const variantToClassName: Record<BoxVariant, string> = {
  base: `${baseClassName}__base`,
  primary: `${baseClassName}__primary`,
  secondary: `${baseClassName}__secondary`,
  clickable: `${baseClassName}__clickable`,
  disabled: `${baseClassName}__disabled`,
};

const spacing = (spacing: string): string =>
  `var(--plm-dt-size-spacing-${spacing})`;

const getPadding = (padding: StyledBoxProps['padding']) => {
  if (typeof padding === 'string') {
    return { '--padding': spacing(padding) };
  }

  if (
    typeof padding === 'object' &&
    padding !== null &&
    'block' in padding &&
    'inline' in padding
  ) {
    return {
      '--padding': `${spacing(padding.block)} ${spacing(padding.inline)}`,
    };
  }

  return {};
};

const dimension = (dimension: string) =>
  `var(--plm-dt-size-dimension-${dimension})`;

const getMaxWidth = (maxWidth: string | undefined) => {
  if (typeof maxWidth === 'string') {
    return { maxWidth: dimension(maxWidth) };
  }

  return {};
};
