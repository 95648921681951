import React from 'react';
import classNames from 'classnames';
import mergeProps from 'merge-props';
import { useFocusRing } from '@react-aria/focus';
import { useHover } from '@react-aria/interactions';
import { useTextField } from '@react-aria/textfield';
import { useId } from '@react-aria/utils';
import { Stack, StackItem } from '../../foundation/layout/Stack';
import { assignRef } from '../../utils/assignRef';
import {
  ValidationState,
  isInvalid,
  isValid,
  hasValidity,
} from '../../utils/validationState';
import { Label } from '../Label';
import { VisuallyHidden } from '../../foundation/VisuallyHidden';
import EyeIcon from './EyeIcon';
import AppendIcon, { Icon } from './AppendIcon';
import './input.css';

type TextFieldProps = Parameters<typeof useTextField>[0];

export type AutoCompleteOption = string | { label: string; value: string };
export type AutoCompleteOptions = readonly AutoCompleteOption[];

type PasswordInputProps = {
  /**
   * The type of input to render. See [MDN](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdeftype).
   */
  type: 'password';
  /**
   * Text used to identify the reveal/toggle password button
   *
   * @example: "Reveal Password"
   */
  togglePasswordButtonText: React.ReactNode;
};

type NumericInputProps = {
  min?: string;
  max?: string;
  step?: string;
};

type BaseInputProps = {
  /**
   * The type of input to render. See [MDN](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdeftype).
   */
  type?:
    | 'text'
    | 'search'
    | 'url'
    | 'tel'
    | 'email'
    | 'date'
    | 'datetime-local'
    | 'color'
    | 'month'
    | 'time'
    | 'week'
    | 'number';
};

export type InputProps = (BaseInputProps | PasswordInputProps) &
  ({
    /** The content to display as the label. */
    label?: React.ReactNode;
    /** The content to display as assistive text in the label */
    secondaryLabel?: React.ReactNode;

    /**
     * Whether the input should display its "valid" or "invalid" visual styling.
     *
     * Setting it to `"valid"`/`"invalid"` will add a visual validation mark in the input.
     * Setting it to `undefined` will not show the mark at all.
     */
    validationState?: ValidationState;

    appendIcon?: undefined | Icon;

    /** Whether the input is disabled. */
    isDisabled?: boolean;

    /** The content to display as an error message. Should have set `validationState={'invalid'}` in case you set this. */
    errorMessage?: React.ReactNode;

    /**
     * **!!DON'T USE THIS PROPERTY!!** Documentation/Demonstration use only.
     */
    UNSAFE__isHovered?: boolean;
    /**
     * **[!!DON'T USE THIS PROPERTY!!](https://youtu.be/otCpCn0l4Wo?t=21)** Documentation/Demonstration use only.
     */
    UNSAFE__isFocused?: boolean;

    /** The current value (controlled). */
    value?: string;

    /** The default value (uncontrolled). */
    defaultValue?: string;

    /** Temporary text that occupies the text input when it is empty. */
    placeholder?: string;

    /** a list of predefined values to suggest to the user for this input. Any
     * values in the list that are not compatible with the `type` are not included
     * in the suggested options. The values provided are suggestions, not
     * requirements: users can select from this predefined list or provide a
     * different value.
     *
     * It is valid on `text`, `search`, `url`, `tel`, `email`, `date`, `month`,
     * `week`, `time`, `datetime-local`, `number`, `range`, and `color`.
     *
     * Suggestions are not supported by the `hidden`, `password`, or `file` types.
     */
    autoCompleteOptions?: AutoCompleteOptions;

    /**
     * Handler that is called when the element receives focus.
     */
    onFocus?: (e: React.FocusEvent) => void;
    /**
     * Handler that is called when the element loses focus.
     */
    onBlur?: (e: React.FocusEvent) => void;
    /**
     * Handler that is called when the element's focus status changes.
     */
    onFocusChange?: (isFocused: boolean) => void;
    /**
     * Handler that is called when a key is pressed.
     */
    onKeyDown?: (e: React.KeyboardEvent) => void;
    /**
     * Handler that is called when a key is released.
     */
    onKeyUp?: (e: React.KeyboardEvent) => void;
    /**
     * Handler that is called when the value changes.
     */
    onChange?: (value: string) => void;
    /**
     * Handler that is called when the user copies text. See MDN.
     */
    onCopy?: React.ClipboardEventHandler<HTMLInputElement>;
    /**
     * Handler that is called when the user cuts text. See MDN.
     */
    onCut?: React.ClipboardEventHandler<HTMLInputElement>;
    /**
     * Handler that is called when the user pastes text. See MDN.
     */
    onPaste?: React.ClipboardEventHandler<HTMLInputElement>;
    /**
     * Handler that is called when a text composition system starts a new text composition session. See MDN.
     */
    onCompositionEnd?: React.CompositionEventHandler<HTMLInputElement>;
    /**
     * Handler that is called when a text composition system completes or cancels the current text composition session. See MDN.
     */
    onCompositionStart?: React.CompositionEventHandler<HTMLInputElement>;
    /**
     * Handler that is called when a new character is received in the current text composition session. See MDN.
     */
    onCompositionUpdate?: React.CompositionEventHandler<HTMLInputElement>;
    /**
     * Handler that is called when text in the input is selected. See MDN.
     */
    onSelect?: React.ReactEventHandler<HTMLInputElement>;
    /**
     * Handler that is called when the input value is about to be modified. See MDN.
     */
    onBeforeInput?: React.FormEventHandler<HTMLInputElement>;
    /**
     * Handler that is called when the input value is modified. See MDN.
     */
    onInput?: React.FormEventHandler<HTMLInputElement>;
  } & TextFieldProps &
    NumericInputProps);

/**
 * Inputs that allow users to input custom text entries with a keyboard.
 */
function Input(props: InputProps, outerRef: React.Ref<HTMLInputElement>) {
  const {
    label,
    secondaryLabel,
    validationState,
    isDisabled,
    isReadOnly,
    errorMessage,
    autoCompleteOptions,
  } = props;

  const innerRef = React.useRef<HTMLInputElement>(null);
  const errorMessageId = useId();
  const dataListId = useId();
  const { togglePasswordVisible, type, passwordVisible } = usePasswordVisible(
    props,
  );
  const { labelProps, inputProps } = useTextField(
    {
      ...props,
      type,
      'aria-errormessage':
        props['aria-errormessage'] ?? errorMessage ? errorMessageId : undefined,
    },
    innerRef,
  );
  const numericInputProps = useNumericInputProps(props);
  const { hoverProps, isHovered } = useHover(props);
  let { isFocusVisible, focusProps } = useFocusRing({
    isTextInput: true,
    autoFocus: props.autoFocus,
  });

  return (
    <Stack
      gap="2"
      className={classNames('plm-c-input', {
        'plm-c-input__hovered': props.UNSAFE__isHovered ?? isHovered,
        'plm-c-input__focused': props.UNSAFE__isFocused ?? isFocusVisible,
        'plm-c-input__invalid': isInvalid(validationState),
        'plm-c-input__valid': isValid(validationState),
        'plm-c-input__disabled': isDisabled,
        'plm-c-input__readonly': isReadOnly,
      })}
    >
      {label && (
        <StackItem>
          <Label {...labelProps} secondary={secondaryLabel}>
            {label}
          </Label>
        </StackItem>
      )}
      <StackItem className="plm-c-input--field-row">
        <input
          {...mergeProps(inputProps, hoverProps, focusProps, numericInputProps)}
          className={classNames('plm-c-input--field', {
            'plm-c-input--field__with-icon':
              typeof props.appendIcon === 'string',
          })}
          ref={assignRef(innerRef, outerRef)}
          list={
            hasAutoCompleteOptions(autoCompleteOptions) ? dataListId : undefined
          }
        />

        <div className="plm-c-input--append">
          {hasValidity(validationState) && (
            <svg width={16} height={16} className="plm-c-input--icon">
              <g fill="none" fillRule="evenodd">
                {isInvalid(validationState) && (
                  <path
                    d="M5 11l6-6m0 6L5 5"
                    stroke="#FFF"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                  />
                )}
                {isValid(validationState) && (
                  <path
                    d="M11 6l-4 4-2-2"
                    stroke="#FFF"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                  />
                )}
              </g>
            </svg>
          )}
          {typeof props.appendIcon === 'string' && (
            <AppendIcon icon={props.appendIcon} />
          )}
          {isPassword(props) && typeof passwordVisible === 'boolean' && (
            <button
              type="button"
              onClick={togglePasswordVisible}
              className="plm-c-input__password--toggle"
            >
              <VisuallyHidden>{props.togglePasswordButtonText}</VisuallyHidden>
              <EyeIcon isOpen={passwordVisible} />
            </button>
          )}
        </div>

        {hasAutoCompleteOptions(autoCompleteOptions) && (
          <datalist id={dataListId}>
            {autoCompleteOptions.map((option) => (
              <option
                key={getValue(option)}
                value={getValue(option)}
                label={getLabel(option)}
              />
            ))}
          </datalist>
        )}
      </StackItem>
      {errorMessage && (
        <StackItem id={errorMessageId} className="plm-c-input--tertiary-text">
          {errorMessage}
        </StackItem>
      )}
    </Stack>
  );
}

Input.displayName = 'Plume__Input';

const _Input = React.forwardRef(Input);

export { _Input as Input };

const hasAutoCompleteOptions = (
  autoCompleteOptions: AutoCompleteOptions | undefined | null,
): autoCompleteOptions is AutoCompleteOptions =>
  Array.isArray(autoCompleteOptions) && autoCompleteOptions.length > 0;

const getValue = (option: AutoCompleteOption): string =>
  typeof option === 'string' ? option : option.value;

const getLabel = (option: AutoCompleteOption): string =>
  typeof option === 'string' ? option : option.label;

const usePasswordVisible = (props: Pick<InputProps, 'type'>) => {
  const [contentVisible, setContentVisible] = React.useState(false);
  const togglePasswordVisible = React.useCallback(() => {
    setContentVisible((current) => !current);
  }, [setContentVisible]);

  const passwordVisible = isPassword(props) ? contentVisible : undefined;
  const inputType = passwordVisible ? 'text' : props.type;

  return { passwordVisible, type: inputType, togglePasswordVisible };
};

const useNumericInputProps = (props: InputProps) => {
  return (
    isNumeric(props) && {
      min: props.min,
      max: props.max,
      step: props.step,
    }
  );
};

const isPassword = <T extends Pick<InputProps, 'type'>>(
  props: T,
): props is Exclude<T, BaseInputProps> => props.type === 'password';

const isNumeric = <T extends Pick<InputProps, 'type'>>(
  props: T,
): props is Exclude<T, BaseInputProps> =>
  props.type === 'date' ||
  props.type === 'month' ||
  props.type === 'week' ||
  props.type === 'time' ||
  props.type === 'datetime-local' ||
  props.type === 'number';
