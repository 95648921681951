import React from 'react';

export const FilledInfoIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 8A8 8 0 110 8a8 8 0 0116 0z"
      fill="var(--plm-dt-color-blue-60)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 4a1 1 0 100 2 1 1 0 000-2z"
      fill="var(--plm-dt-color-white)"
    />
    <rect
      x={7}
      y={7}
      width={2}
      height={5}
      rx={1}
      fill="var(--plm-dt-color-white)"
    />
  </svg>
);
