import { HiddenSelect, useSelect } from '@react-aria/select';
import { useId } from '@react-aria/utils';
import { Item } from '@react-stately/collections';
import { useSelectState } from '@react-stately/select';
import { AriaSelectProps } from '@react-types/select';
import React, { PropsWithChildren } from 'react';
import { Stack, StackItem } from '../../foundation/layout/Stack';
import { Label } from '../Label';
import './select.css';
import { SelectAtom } from './SelectAtom';

export { Item as SelectItem };
export type SelectItemProps = PropsWithChildren<typeof Item>;

type ValidationState = 'valid' | 'invalid';

type SelectStateProps<Key extends string, T extends object> = {
  /**
   * The controls name of the associated data point submitted to the server.
   */
  name?: string;

  /**
   * The element's unique identifier. See [MDN](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/id).
   */
  id?: string;

  /** Temporary text that occupies the input when it is empty */
  placeholder: React.ReactNode;

  /** Whether the items are currently loading. */
  isLoading?: boolean;
  /** Handler that is called when more items should be loaded, e.g. while scrolling near the bottom. */
  onLoadMore?: () => void;
  /** Whether the input is disabled. */
  isDisabled?: boolean;
  /** Whether the input can be selected but not changed by the user. */
  isReadOnly?: boolean;
  validationState?: ValidationState;

  /**
   * Defines a string value that labels the current element.
   */
  'aria-label'?: string;

  /**
   * Identifies the element (or elements) that labels the current element.
   */
  'aria-labelledby'?: string;

  /**
   * Identifies the element (or elements) that describes the object.
   */
  'aria-describedby'?: string;

  /**
   * Identifies the element (or elements) that provide a detailed, extended description for the object.
   */
  'aria-details'?: string;

  /** The contents of the collection. */
  children: AriaSelectProps<T>['children'];
  /** Item objects in the collection. */
  items?: Iterable<T>;
  /** The item keys that are disabled. These items cannot be selected, focused, or otherwise interacted with. */
  disabledKeys?: Iterable<Key>;

  /** Sets the open state of the menu. */
  isOpen?: boolean;
  /** Sets the default open state of the menu. */
  defaultOpen?: boolean;
  /** Method that is called when the open state of the menu changes. */
  onOpenChange?: (isOpen: boolean) => void;

  /** Whether the collection allows empty selection. */
  disallowEmptySelection?: boolean;
  /** The currently selected key in the collection (controlled). */
  selectedKey?: Key;
  /** The initial selected key in the collection (uncontrolled). */
  defaultSelectedKey?: Key;
  /** Handler that is called when the selection changes. */
  onSelectionChange?: (key: Key) => void;

  /** The content to display as an error message. Should have set `validationState={'invalid'}` in case you set this. */
  errorMessage?: React.ReactNode;

  /** Identifies the element that provides an error message for the object. */
  'aria-errormessage'?: string;
};

export type SelectProps<
  Key extends string = string,
  T extends object = object
> = SelectStateProps<Key, T> & AriaSelectProps<T>;

export function Select<Key extends string = string, T extends object = object>(
  props: SelectProps<Key, T>,
) {
  const state = useSelectState<T>({
    ...props,
    isOpen: props.isDisabled ? false : props.isOpen,
  });

  const ref = React.useRef(null);

  const errorMessageId = useId();

  const { labelProps, triggerProps, valueProps, menuProps } = useSelect(
    props,
    state,
    ref,
  );

  return (
    <Stack gap="2">
      {props.label && (
        <StackItem>
          <Label {...labelProps}>{props.label}</Label>
        </StackItem>
      )}

      <StackItem>
        <HiddenSelect
          state={state}
          triggerRef={ref}
          label={props.label}
          name={props.name}
          isDisabled={props.isDisabled}
        />
        <SelectAtom
          {...props}
          state={state}
          menuProps={menuProps}
          triggerProps={triggerProps}
          valueProps={valueProps}
          ref={ref}
        />
      </StackItem>
      {props.errorMessage && (
        <StackItem id={errorMessageId} className="plm-select--tertiary-text">
          {props.errorMessage}
        </StackItem>
      )}
    </Stack>
  );
}

Select.displayName = 'Plume__Select';
