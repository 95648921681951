import React, { SVGProps } from 'react';
import { getPxFromRem } from '../../utils/getPxFromRem';
import { useCSSCustomProperties } from '../../utils/useCSSCustomProperties';

export const Icon = (props: SVGProps<SVGSVGElement>) => {
  const [
    {
      '--plm-dt-progress-circle-diameter-default': diameter,
      '--plm-dt-progress-circle-stroke-width-default': strokeWidth,
      '--plm-dt-size-base': base,
    },
    ref,
  ] = useCSSCustomProperties(
    '--plm-dt-progress-circle-diameter-default',
    '--plm-dt-progress-circle-stroke-width-default',
    '--plm-dt-size-base',
  );

  const center = getPxFromRem(diameter, base);
  const r = `calc(${diameter} - ${strokeWidth})`;
  const c = `calc(2 * ${r} * ${Math.PI})`;
  const offset = `calc(${c} - (3 / 4) * ${c})`;

  return (
    <svg
      ref={ref}
      width={center * 2}
      height={center * 2}
      fill="none"
      strokeWidth={strokeWidth}
      {...props}
    >
      <circle
        role="presentation"
        cx={center}
        cy={center}
        r={r}
        stroke="currentColor"
        strokeLinecap="round"
        strokeDasharray={c}
        strokeDashoffset={offset}
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          begin="0s"
          dur="2000ms"
          from="0 8 8"
          to="360 8 8"
          repeatCount="indefinite"
        />
      </circle>
    </svg>
  );
};
