import { FocusScope } from '@react-aria/focus';
import { AriaListBoxOptions, useListBox } from '@react-aria/listbox';
import { DismissButton, useOverlay } from '@react-aria/overlays';
import { SelectState } from '@react-stately/select';
import React, { forwardRef, useRef, ForwardedRef, CSSProperties } from 'react';
import classNames from 'classnames';
import mergeProps from 'merge-props';
import { assignRef } from '../../utils/assignRef';
import { Option } from './Option';

export type Placement = 'top' | 'bottom';

export const isPlacement = (placement: string): placement is Placement =>
  placement === 'top' || placement === 'bottom';

export type ListBoxPopupProps<T> = {
  state: SelectState<T>;
  style?: CSSProperties;
  placement: Placement;
} & AriaListBoxOptions<T>;

const ListBoxPopup = <T extends unknown>(
  {
    state,
    onLoadMore,
    isLoading,
    placement,
    ...otherProps
  }: ListBoxPopupProps<T>,
  outerRef: ForwardedRef<HTMLUListElement>,
) => {
  const listRef = useRef<HTMLUListElement | null>(null);
  const overlayRef = useRef<HTMLDivElement | null>(null);

  const { listBoxProps } = useListBox<T>(
    {
      ...otherProps,
      isLoading,
      onLoadMore,
      autoFocus: state.focusStrategy ?? true,
      disallowEmptySelection: true,
    },
    state,
    listRef,
  );

  const { overlayProps } = useOverlay(
    {
      onClose: () => state.close(),
      shouldCloseOnBlur: true,
      isOpen: state.isOpen,
      isDismissable: true,
    },
    overlayRef,
  );

  return (
    <FocusScope restoreFocus>
      <div {...overlayProps} ref={overlayRef}>
        <DismissButton onDismiss={() => state.close()} />
        <ul
          {...mergeProps(listBoxProps, otherProps)}
          ref={assignRef(listRef, outerRef)}
          className={classNames(
            'plm-c-select--list-box',
            `plm-c-select--list-box__${placement}`,
          )}
        >
          {Array.from(state.collection).map((item, index, list) => (
            <Option<T>
              key={item.key}
              item={item}
              state={state}
              onInViewChange={
                index === list.length - 1 &&
                !isLoading &&
                typeof onLoadMore === 'function'
                  ? (isInView) => {
                      isInView && onLoadMore();
                    }
                  : null
              }
            />
          ))}
        </ul>
        <DismissButton onDismiss={() => state.close()} />
      </div>
    </FocusScope>
  );
};

const _ListBoxPopup = forwardRef(ListBoxPopup);

_ListBoxPopup.displayName = 'Plume__Select__ListBoxPopup';
export { _ListBoxPopup as ListBoxPopup };
