import {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useLayoutEffect,
} from 'react';
import { TabsConfig, TabsLabelItem, Value } from './TabsContext';

export const useTabRegistry = ({
  value,
  userCustomInitialValue,
}: {
  value: Value | undefined;
  userCustomInitialValue: Value | undefined;
}) => {
  // selfValue manages the state if no external value is applied
  const [selfValue, setSelfValue] = useState<Value | undefined>(
    userCustomInitialValue,
  );
  const [tabs, setTabs] = useState<Array<TabsLabelItem>>([]);

  const register = useCallback((next: TabsLabelItem) => {
    setTabs((last) => {
      const hasItem = last.find((item) => item.value === next.value);
      // new registration
      if (!hasItem) return [...last, next];
      // change existing registration
      return last.map((item) => {
        if (item.value !== next.value) return item;
        return {
          ...item,
          label: next.label,
          disabled: next.disabled,
        };
      });
    });
  }, []);

  // Keep own state in sync
  useEffect(() => {
    if (value === undefined) return;
    setSelfValue(value);
  }, [value]);

  // set first item from registry if no initial state set
  // Using layout effect here to prevent flash of content
  useLayoutEffect(() => {
    if (selfValue !== undefined || tabs.length === 0) return;

    setSelfValue(tabs[0].value);
  }, [selfValue, tabs]);

  const initialValue = useMemo<TabsConfig>(
    () => ({
      register,
      currentValue: selfValue,
      inGroup: true,
    }),
    [selfValue, register],
  );

  return { selfValue, setSelfValue, tabs, initialValue };
};
