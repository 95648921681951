import React from 'react';
import classNames from 'classnames';
import { PolymorphicComponentProps, Box } from 'react-polymorphic-box';
import './label.css';
import { Stack, StackItem } from '../../foundation/layout/Stack';

export interface LabelOwnProps {
  /**
   * spacing to separate inline input elements
   * @default false
   */
  hasSpacingBefore?: boolean;

  secondary?: React.ReactNode;
}

const defaultType = 'label';

export type LabelProps<
  ElementType extends React.ElementType = React.ElementType
> = PolymorphicComponentProps<ElementType, LabelOwnProps>;

export function Label({
  className,
  children,
  secondary,
  hasSpacingBefore,
  as = defaultType,
  ...props
}: LabelProps) {
  const labelClassName = classNames(
    'plm-c-label',
    hasSpacingBefore && 'plm-c-label__spacing-before',
    className,
  );

  if (secondary) {
    return (
      <Stack gap="1" {...props} as={as} className={labelClassName}>
        <StackItem>{children}</StackItem>
        {secondary && (
          <StackItem className="plm-c-label--secondary">{secondary}</StackItem>
        )}
      </Stack>
    );
  }

  return (
    <Box {...props} as={as} className={labelClassName}>
      {children}
    </Box>
  );
}

Label.displayName = 'Plume__Label';
