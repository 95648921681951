import { useCallback, useLayoutEffect, useRef, useState } from 'react';

const getPropertyFromElement = <PropertyNames extends string>(
  element: Element,
  propertyNames: readonly PropertyNames[],
): Record<PropertyNames, string> => {
  const computedStyle = window.getComputedStyle(element);

  return Object.fromEntries(
    propertyNames.map(
      (propertyName) =>
        [propertyName, computedStyle.getPropertyValue(propertyName)] as const,
    ),
  ) as Record<PropertyNames, string>;
};

export const useCSSCustomProperties = <PropertyNames extends string>(
  ...propertyNames: readonly PropertyNames[]
) => {
  const ref = useRef<Element | null>(null);

  const [propertyValues, setPropertyValue] = useState(() =>
    // initially the value with an eventual `:root` value to prevent empty renderings
    getPropertyFromElement(document.body, propertyNames),
  );

  // use a setRef callback here to get an event if the element changes (e.g. in the DOM for the first time, with the ref)
  const setRef = useCallback(
    (element: Element | null) => {
      // save the ref for the useLayoutEffect that may occur over time
      ref.current = element;

      const computedPropertyValue = getPropertyFromElement(
        // always fall back to the body if the element gets removed. Again, prevents empty values most of the time
        element ?? document.body,
        propertyNames,
      );
      setPropertyValue(computedPropertyValue);
    },
    // `propertyNames` is a rest parameter and will always get a new reference. But for this callback the content is interesting
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...propertyNames],
  );

  useLayoutEffect(() => {
    const computedPropertyValue = getPropertyFromElement(
      ref.current ?? document.body,
      propertyNames,
    );
    setPropertyValue(computedPropertyValue);
    // disabled for the same reason as in the useCallback ⏫
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...propertyNames]);

  return [propertyValues, setRef] as const;
};
