export type ValidationState = 'valid' | 'invalid';

export const isInvalid = (
  validationState: ValidationState | undefined,
): validationState is 'invalid' => validationState === 'invalid';

export const isValid = (
  validationState: ValidationState | undefined,
): validationState is 'valid' => validationState === 'valid';

export const hasValidity = (
  validationState: ValidationState | undefined,
): validationState is ValidationState => typeof validationState === 'string';
