import React, { useCallback, useRef } from 'react';
import classNames from 'classnames';
import { useFocusManager, useFocusRing } from '@react-aria/focus';
import { useButton } from '@react-aria/button';
import mergeProps from 'merge-props';
import { TabsLabelItem } from './TabsContext';
import { Text } from '../../foundation/typography/Text';

interface TabsButtonProps {
  active: boolean;
  item: TabsLabelItem;
  onPress: (item: TabsLabelItem) => void;
}

export const TabsButton = ({ item, active, onPress }: TabsButtonProps) => {
  const ref = useRef<HTMLButtonElement>(null);
  const { isFocusVisible, focusProps } = useFocusRing();
  const focusManager = useFocusManager();
  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLButtonElement>) => {
      switch (e.key) {
        case 'ArrowRight':
        case 'ArrowDown':
          focusManager.focusNext({ wrap: true });
          return;
        case 'ArrowLeft':
        case 'ArrowUp':
          focusManager.focusPrevious({ wrap: true });
          return;
      }
    },
    [focusManager],
  );

  const handlePress = useCallback(() => onPress(item), [onPress, item]);

  const { buttonProps } = useButton(
    {
      onPress: handlePress,
      onKeyDown: handleKeyDown,
      isDisabled: item.disabled,
    },
    ref,
  );

  return (
    <Text
      as="button"
      className={classNames('plm-c-tabs__tab', {
        'plm-c-tabs__tab--active': active,
        'plm-c-tabs__tab--disabled': item.disabled,
        'plm-c-tabs__tab--focused': isFocusVisible,
      })}
      {...mergeProps(focusProps, buttonProps)}
      role="tab"
      aria-selected={active}
    >
      <span className="plm-c-tabs__tab__label">{item.label}</span>

      <span className="plm-c-tabs__tab__measurement" aria-hidden>
        {item.label}
      </span>
    </Text>
  );
};

TabsButton.displayName = 'Plume__TabsButton';
