import React, { ReactNode, createContext, useState, useContext } from 'react';
import { createPortal } from 'react-dom';

const getBaseElement = () => {
  if (typeof window === 'undefined') return undefined;

  return document.body;
};

const PortalContext = createContext<HTMLElement | undefined>(getBaseElement());

export interface PortalProviderProps {
  children: (options: {
    containerRef: (instance: HTMLElement | null) => void;
  }) => ReactNode;
}

/**
 * Provide a new place to render content with `Portal`. Use this inside of dialogs or other "layers".
 * If no provider can be found, falls back to `document.body`.
 */
export function PortalProvider({ children }: PortalProviderProps) {
  const [container, setContainer] = useState<HTMLElement | undefined>(
    getBaseElement(),
  );

  return (
    <PortalContext.Provider value={container}>
      {children({
        containerRef: (container) => setContainer(container ?? document.body),
      })}
    </PortalContext.Provider>
  );
}

export const usePortalContainer = () => useContext(PortalContext);

export interface PortalProps {
  /** will be rendered into the nearest portal reference created with `Portal.Provider` */
  children: ReactNode;
}

/**
 * Renders children into the nearest provided element reference. Use this component for overlays to exceed boundaries prevented by overflow: none or in dialogs
 */
export function Portal({ children }: PortalProps) {
  const container = usePortalContainer();

  return container ? createPortal(children, container) : null;
}

Portal.Provider = PortalProvider;
