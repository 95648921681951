import React from 'react';
import { useProgressBar } from '@react-aria/progress';
import { useId } from '@react-aria/utils';
import classNames from 'classnames';
import './spinner.css';
import { Icon } from './Icon';

export type SpinnerProps = {
  className?: string;

  /** The content to display as the label. Shown beneath the bar. */
  label?: React.ReactNode;

  /** The content to dispay as the details. Shown above the bar. */
  details?: React.ReactNode;

  /** The element's unique identifier. See [MDN](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/id). */
  id?: string;
  /** Defines a string value that labels the current element. */
  'aria-label'?: string;
  /** Identifies the element (or elements) that labels the current element. */
  'aria-labelledby'?: string;
  /** Identifies the element (or elements) that describes the object. */
  'aria-describedby'?: string;
  /** Identifies the element (or elements) that provide a detailed, extended description for the object. */
  'aria-details'?: string;
};

/**
 * Progress show either determinate or indeterminate progress of an operation over time.
 */
function Spinner(props: SpinnerProps, ref: React.Ref<HTMLDivElement>) {
  const { label, details } = props;
  const detailsId = useId();
  const ariaDetails = getAriaDetails(
    props['aria-details'],
    details && detailsId,
  );

  const { progressBarProps, labelProps } = useProgressBar({
    ...props,
    'aria-details': ariaDetails,
  });

  return (
    <div
      ref={ref}
      {...progressBarProps}
      className={classNames('plm-c-spinner', props.className)}
    >
      {details && (
        <div id={detailsId} className="plm-c-spinner__details">
          {details}
        </div>
      )}

      <Icon className="plm-c-spinner__icon" />
      {label && (
        <div {...labelProps} className="plm-c-spinner__label">
          {label}
        </div>
      )}
    </div>
  );
}

Spinner.displayName = 'Plume__Spinner';

const _Spinner = React.forwardRef(Spinner);

export { _Spinner as Spinner };

const getAriaDetails = (
  ...details: (string | false | 0 | null | undefined)[]
): string | undefined => {
  return details.filter(Boolean).join(' ').trim() || undefined;
};
