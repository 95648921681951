import React from 'react';
import classnames from 'classnames';
import { ClassValue } from 'classnames/types';
import { FocusRing } from '@react-aria/focus';
import isPropValid from '@emotion/is-prop-valid';
import {
  ApparentComponentProps,
  isDOMTypeElement,
  isFunction,
} from './helpers';

type ClassParamFn<TProps extends {}> = (props: TProps) => ClassValue;
type ClassParam<TProps extends {}> = ClassValue | ClassParamFn<TProps>;

type StyleParamFn<TProps extends {}, TCustomProperties extends {}> = (
  props: TProps,
) => (React.CSSProperties & TCustomProperties) | undefined | null;
type StyleParam<TProps extends {}, TCustomProperties extends {}> =
  | (React.CSSProperties & TCustomProperties)
  | StyleParamFn<TProps, TCustomProperties>;

/**
 * Generates the className(s) with the help of `classnames`. Make them dynamic with the properties
 */
const parseParamsToClassName = <TProps extends {}>(
  props: TProps,
  ...params: readonly ClassParam<TProps>[]
) => {
  const className = params
    .map((param) => classnames(isFunction(param) ? param(props) : param))
    .join(' ')
    .trim();

  // prevent from creating a `class` property in the DOM
  if (className === '') return undefined;

  return className;
};

/**
 * Generates the style(s). Make them dynamic with the properties
 */
const parseParamsToStyles = <TProps extends {}>(
  props: TProps,
  ...styles: readonly (StyleParam<TProps, {}> | undefined | null)[]
): React.CSSProperties => {
  const style = Object.assign(
    {},
    ...styles.map((param) => (isFunction(param) ? param(props) : param)),
  );

  return style;
};

/**
 * Removes all props that are not valid DOM attributes.
 */
const cleanUpProps = <TProps extends {}>(props: TProps) =>
  Object.fromEntries(
    Object.entries(props).filter(([prop]) => isPropValid(prop)),
  );

/**
 * The very own props that this componentizedClassname function needs to exists. This props are always valid regardless of the element type
 */
export type OwnProps<TElement extends React.ElementType = React.ElementType> = {
  className?: string;
  style?: React.CSSProperties;
  as?: TElement;
};

export type ClassnamesComponentsParams<
  Props extends {},
  TCustomProperties extends {}
> = {
  className?: ClassParam<Props>;
  classNames?: readonly ClassParam<Props>[];
  style?: StyleParam<Props, TCustomProperties>;
  styles?: readonly StyleParam<Props, TCustomProperties>[];
  /* CSS class to apply when the element is focused */
  focusClassName?: string;
  /* CSS class to apply when the element has keyboard focus */
  focusRingClassName?: string;
};

/**
 * creates a component filled with a className generated by the given parameter. Supports polymorphic components with the `as` property.
 *
 * @example
 * ```tsx
 * const Button = classnamesComponents<ButtonProps>('button')({
 *   classNames: ['some-class', props => props.valid ? 'valid' : 'invalid'],
 *   style: props => ({ width: props.width })
 * });
 * ```
 */
const classnamesComponents = <
  TExtraProps extends {} = {},
  TCustomProperties extends {} = {}
>(
  defaultElement: keyof JSX.IntrinsicElements,
  { displayName }: { displayName?: string } = {},
) => ({
  className: classNameFromParams,
  classNames = [],
  style: styleFromParams,
  styles = [],
  focusClassName,
  focusRingClassName,
}: ClassnamesComponentsParams<
  Omit<OwnProps, 'as'> & TExtraProps,
  TCustomProperties
> = {}) => {
  // construct the component to add a metadata later
  const Component = <
    TComponent extends
      | keyof JSX.IntrinsicElements
      | React.JSXElementConstructor<any> = typeof defaultElement
  >(
    props: OwnProps<TComponent> &
      TExtraProps &
      ApparentComponentProps<TComponent>,
  ) => {
    const Element = props.as ?? defaultElement;

    const shouldFilterProps = isDOMTypeElement(Element);
    const filteredProps = shouldFilterProps ? cleanUpProps(props) : props;

    const className = parseParamsToClassName(
      props,
      props.className,
      classNameFromParams,
      ...classNames,
    );

    const style = parseParamsToStyles(
      props,
      styleFromParams,
      ...styles,
      props.style,
    );

    const element = React.createElement(Element, {
      ...filteredProps,
      className,
      style,
    });

    if (focusClassName || focusRingClassName) {
      return (
        <FocusRing
          focusClass={focusClassName}
          focusRingClass={focusRingClassName}
        >
          {element}
        </FocusRing>
      );
    }

    return element;
  };

  if (displayName) Component.displayName = displayName;

  return Component;
};

export default classnamesComponents;
