import * as tokensRelative from '@messagebird/plume-design-tokens';
import type * as tokensAbsolute from '@messagebird/plume-design-tokens/dist/js/px';
import React, { Fragment, FunctionComponent, PropsWithChildren } from 'react';

type Tokens = typeof tokensRelative | typeof tokensAbsolute;

export type PlumeProviderProps = PropsWithChildren<{
  value: Tokens;
}>;

// Leave this in to have flexibility in the future. The Provider functionality was replaced with `useCSSCustomProperty` internally
// It might come back one day
export const PlumeProvider: FunctionComponent<PlumeProviderProps> = ({
  children,
}) => <Fragment>{children}</Fragment>;
