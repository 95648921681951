import React from 'react';

export const ExclamationMarkIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={2} height={8} viewBox="0 0 2 8" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 8a1 1 0 100-2 1 1 0 000 2z"
      fill="currentColor"
    />
    <rect
      x={2}
      y={5}
      width={2}
      height={5}
      rx={1}
      transform="rotate(-180 2 5)"
      fill="currentColor"
    />
  </svg>
);
export const FilledExclamationMarkIcon = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg width={16} height={14} viewBox="0 0 16 14" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.425.36L.083 13.024c-.239.436.066.976.551.976h14.732c.486 0 .79-.54.55-.976L8.624.36a.68.68 0 00-1.199 0z"
      fill="var(--plm-dt-color-yellow-60)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 12a1 1 0 100-2 1 1 0 000 2z"
      fill="var(--plm-dt-color-white)"
    />
    <rect
      x={9}
      y={9}
      width={2}
      height={5}
      rx={1}
      transform="rotate(-180 9 9)"
      fill="var(--plm-dt-color-white)"
    />
  </svg>
);
