import React from 'react';
import { useProgressBar } from '@react-aria/progress';
import { useId } from '@react-aria/utils';
import classNames from 'classnames';
import './progress.css';

export type ProgressProps = {
  className?: string;

  /** The content to display as the label. Shown beneath the bar. */
  label?: React.ReactNode;

  /** The content to dispay as the details. Shown above the bar. */
  details?: React.ReactNode;

  /**
   * The content to display as the value's label (e.g. 1 of 4).
   * This value is currently not visualized, but can be used for a11y reasons.
   */
  valueLabel?: React.ReactNode;

  /** The current value. */
  value?: number;

  /**
   * The smallest value possible for the progress bar.
   * @default 0
   */
  minValue?: number;

  /**
   * The largest value possible for the progress bar.
   * @default 100
   */
  maxValue?: number;

  /**
   * Whether presentation is indeterminate when progress isn't known.
   * @default false
   */
  isIndeterminate?: boolean;

  /**
   * The display format of the value label.
   * @default {style: 'percent'}
   */
  formatOptions?: Intl.NumberFormatOptions;

  /** The element's unique identifier. See [MDN](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/id). */
  id?: string;
  /** Defines a string value that labels the current element. */
  'aria-label'?: string;
  /** Identifies the element (or elements) that labels the current element. */
  'aria-labelledby'?: string;
  /** Identifies the element (or elements) that describes the object. */
  'aria-describedby'?: string;
  /** Identifies the element (or elements) that provide a detailed, extended description for the object. */
  'aria-details'?: string;
};

/**
 * Progress show either determinate or indeterminate progress of an operation over time.
 */
function Progress(props: ProgressProps, ref: React.Ref<HTMLDivElement>) {
  const {
    label,
    value,
    minValue = 0,
    maxValue = 100,
    details,
    isIndeterminate,
  } = props;
  const detailsId = useId();
  const ariaDetails = getAriaDetails(
    props['aria-details'],
    details && detailsId,
  );

  const { progressBarProps, labelProps } = useProgressBar({
    ...props,
    'aria-details': ariaDetails,
  });

  // Calculate the width of the progress bar as a percentage
  const percentage = value ? (value - minValue) / (maxValue - minValue) : NaN;
  // if no value is emitted (e.g. if `isIndeterminate`), percentage would be `NaN`
  // make sure we will not end up with `NaN%`
  const barWidth = !isIndeterminate
    ? `${Math.round(percentage * 100) || 0}%`
    : undefined;

  return (
    <div
      ref={ref}
      {...progressBarProps}
      className={classNames('plm-c-progress-bar', props.className)}
    >
      {details && (
        <div id={detailsId} className="plm-c-progress-bar__details">
          {details}
        </div>
      )}
      <div className="plm-c-progress-bar__background">
        <div
          style={
            // type casting is needed because CSSProperties does not allow custom properties
            { '--bar-width': barWidth } as React.CSSProperties
          }
          className={classNames(
            'plm-c-progress-bar__foreground',
            isIndeterminate && 'plm-c-progress-bar__foreground--indeterminate',
          )}
        />
      </div>
      {label && (
        <div {...labelProps} className="plm-c-progress-bar__label">
          {label}
        </div>
      )}
    </div>
  );
}

Progress.displayName = 'Plume__Progress';

const _Progress = React.forwardRef(Progress);

export { _Progress as Progress };

const getAriaDetails = (
  ...details: (string | false | 0 | null | undefined)[]
): string | undefined => {
  return details.filter(Boolean).join(' ').trim() || undefined;
};
