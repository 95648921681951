import React from 'react';
import { PolymorphicComponentProps } from 'react-polymorphic-box';
import mergeProps from 'merge-props';
import classNames from 'classnames';
import { Text } from '../../foundation/typography/Text';
import './tag.css';

type Variant =
  | 'green'
  | 'red'
  | 'yellow'
  | 'orange'
  | 'blue'
  | 'gray'
  | 'purple';

export type TagOwnProps = {
  /** Content of the tag. Make sure it's descriptive as this is the text assistive technology would get announced */
  children: React.ReactNode;

  /**
   * determine which variant to show. Pick one of the color variants.
   */
  variant: Variant;

  /**
   * Which icon to show, if any. Leave empty or apply null for a version without icons
   *
   * @default undefined
   */
  icon?: React.ReactNode | undefined | null;
};

export type TagProps<
  ElementType extends React.ElementType
> = PolymorphicComponentProps<ElementType, TagOwnProps>;

const defaultElement = 'div';

export const Tag = <
  ElementType extends React.ElementType = typeof defaultElement
>({
  icon,
  variant,
  children,
  ...props
}: TagProps<ElementType>) => {
  return (
    <Text
      {...mergeProps(
        {
          className: classNames('plm-c-tag', {
            'plm-c-tag__with-icon': Boolean(icon),
            'plm-c-tag__green': variant === 'green',
            'plm-c-tag__red': variant === 'red',
            'plm-c-tag__yellow': variant === 'yellow',
            'plm-c-tag__orange': variant === 'orange',
            'plm-c-tag__blue': variant === 'blue',
            'plm-c-tag__gray': variant === 'gray',
            'plm-c-tag__purple': variant === 'purple',
          }),
        },
        props,
      )}
    >
      {icon && (
        <figure className="plm-c-tag--icon" aria-hidden>
          {icon}
        </figure>
      )}
      <span>{children}</span>
    </Text>
  );
};

Tag.defaultProps = { as: defaultElement };

Tag.displayName = 'Plume__Tag';
