/* eslint-disable jsx-a11y/no-autofocus */
import classNames from 'classnames';
import React, {
  ElementType,
  FunctionComponent,
  HTMLAttributes,
  PropsWithChildren,
  ReactNode,
} from 'react';
import { Box } from 'react-polymorphic-box';
import {
  FilledCheckmarkIcon,
  FilledExclamationMarkIcon,
  FilledInfoIcon,
  FilledXIcon,
} from '../../icons';
import { Link } from '../Link';
import { CloseButton, CloseButtonProps } from './CloseButton';
import './notification.css';

type NotificationVariant = 'info' | 'alert' | 'error' | 'success';

type CloseableNotificationProps = {
  /**
   * Handler that is called when the close button is pressed
   */
  onClose: CloseButtonProps['onClose'];
  /**
   * Text used to identify the close button.
   */
  closeText: ReactNode;
  /**
   * Either to show or not show the close icon
   */
  isClosable: true;
};

type StaticNotificationProps = {
  /**
   * Handler that is called when the close button is pressed
   */
  onClose?: never;
  /**
   * Text used to identify the close button.
   */
  closeText?: never;
  /**
   * @default false
   */
  isClosable?: false;
};

export type NotificationProps = PropsWithChildren<
  (StaticNotificationProps | CloseableNotificationProps) & {
    variant: NotificationVariant;

    /**
     * Whether the close button should receive focus on render.
     */
    autoFocus?: boolean;

    /** The element's unique identifier. See [MDN](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/id).*/
    id?: string;
    className?: string | undefined;

    /**
     * Indicates what semantic the content has. For time-sensitive and important content, apply the [`"alert"` role](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/Alert_Role).
     * Because of its intrusive nature, the alert role must be used sparingly and only in situations where the user's immediate attention is required. Dynamic changes that are less urgent should use a less aggressive method, such as `aria-live="polite"` or [other live region roles](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Live_Regions).
     */
    role?: HTMLAttributes<HTMLDivElement>['role'];
    /**
     * ARIA live region to announce the content dynamically on change. See [MDN](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Live_Regions)
     */
    'aria-live'?: HTMLAttributes<HTMLDivElement>['aria-live'];

    /**
     * This is to overwrite the default behaviour in focus handling
     *
     * **!!DON'T USE THIS PROPERTY!!** Documentation/Demonstration use only.
     */
    UNSAFE_closeFocusVisible?: boolean;

    /**
     * Element used to render the item in the DOM. Use any semantic element or component that fits your needs. It will not allow to pass down properties.
     *
     * @default 'div'
     */
    as?: ElementType;
  }
>;

/**
 * Notifications tell users when something happened in the application. They display important information that users should to acknowledge but is not blocking them.
 */
export const Notification: FunctionComponent<NotificationProps> & {
  Link: typeof Link;
} = ({
  variant,
  isClosable,
  closeText,
  onClose,
  autoFocus,
  children,
  className,
  UNSAFE_closeFocusVisible,
  ...props
}) => {
  return (
    <Box
      className={classNames(
        'plm-c-notification',
        `plm-c-notification__${variant}`,
        `plm-c-notification__${isClosable ? 'closable' : 'static'}`,
        className,
      )}
      {...props}
    >
      <Icon variant={variant} className="plm-c-notification--icon" />
      <div className="plm-c-notification--children">{children}</div>
      {isClosable && closeText && onClose && (
        <CloseButton
          onClose={onClose}
          autoFocus={autoFocus}
          closeFocusVisible={UNSAFE_closeFocusVisible}
        >
          {closeText}
        </CloseButton>
      )}
    </Box>
  );
};

Notification.Link = Link;

const Icon = ({
  variant,
  className,
}: {
  variant: NotificationVariant;
  className: string;
}) => {
  switch (variant) {
    case 'success':
      return <FilledCheckmarkIcon className={className} />;

    case 'alert':
      return <FilledExclamationMarkIcon className={className} />;

    case 'error':
      return <FilledXIcon className={className} />;

    case 'info':
      return <FilledInfoIcon className={className} />;
  }
};
