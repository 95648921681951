import React from 'react';
import classNames from 'classnames';
import mergeProps from 'merge-props';
import { assignRef } from '../../utils/assignRef';
import { ValidationState, isInvalid } from '../../utils/validationState';
import { useFocusRing } from '@react-aria/focus';
import { useHover } from '@react-aria/interactions';
import { useTextField } from '@react-aria/textfield';
import { useId } from '@react-aria/utils';
import { Stack, StackItem } from '../../foundation/layout/Stack';
import { Label } from '../Label';
import './textarea.css';

type TextFieldProps = Parameters<typeof useTextField>[0];

export type TextareaProps = {
  /** The content to display as the label. */
  label?: React.ReactNode;
  /** The content to display as assistive text in the label */
  secondaryLabel?: React.ReactNode;
  /** Whether the input is disabled. */
  isDisabled?: boolean;
  /**
   * Setting it to `"invalid"` will display the error colour scheme.
   * Setting it to `"invalid" or undefined` will display the default colour scheme.
   */
  validationState?: ValidationState;
  /**
   * The content to display as an error message.
   * *NOTE: `validationState={'invalid'}` is required when displaying an error message.*
   */
  errorMessage?: React.ReactNode;

  /**
   * **!!DON'T USE THIS PROPERTY!!**
   * Documentation/Demonstration use only.
   */
  UNSAFE__isHovered?: boolean;
  /**
   * **!!DON'T USE THIS PROPERTY!!**
   * Documentation/Demonstration use only.
   */
  UNSAFE__isFocused?: boolean;
  /** The current value (controlled). */
  value?: string;
  /** The default value (uncontrolled). */
  defaultValue?: string;
  /** Temporary text that occupies the text input when it is empty. */
  placeholder?: string;

  /** Control the resizeability of the text area according to
   * the spec: https://developer.mozilla.org/en-US/docs/Web/CSS/resize
   * @default 'both'
   */
  resize?: 'both' | 'horizontal' | 'vertical' | 'none';

  /**
   * Handler that is called when the element receives focus.
   */
  onFocus?: (e: React.FocusEvent) => void;
  /**
   * Handler that is called when the element loses focus.
   */
  onBlur?: (e: React.FocusEvent) => void;
  /**
   * Handler that is called when the element's focus status changes.
   */
  onFocusChange?: (isFocused: boolean) => void;
  /**
   * Handler that is called when a key is pressed.
   */
  onKeyDown?: (e: React.KeyboardEvent) => void;
  /**
   * Handler that is called when a key is released.
   */
  onKeyUp?: (e: React.KeyboardEvent) => void;
  /**
   * Handler that is called when the value changes.
   */
  onChange?: (value: string) => void;
  /**
   * Handler that is called when the user copies text. See MDN.
   */
  onCopy?: React.ClipboardEventHandler<HTMLTextAreaElement>;
  /**
   * Handler that is called when the user cuts text. See MDN.
   */
  onCut?: React.ClipboardEventHandler<HTMLTextAreaElement>;
  /**
   * Handler that is called when the user pastes text. See MDN.
   */
  onPaste?: React.ClipboardEventHandler<HTMLTextAreaElement>;
  /**
   * Handler that is called when a text composition system starts a new text composition session. See MDN.
   */
  onCompositionEnd?: React.CompositionEventHandler<HTMLTextAreaElement>;
  /**
   * Handler that is called when a text composition system completes or cancels the current text composition session. See MDN.
   */
  onCompositionStart?: React.CompositionEventHandler<HTMLTextAreaElement>;
  /**
   * Handler that is called when a new character is received in the current text composition session. See MDN.
   */
  onCompositionUpdate?: React.CompositionEventHandler<HTMLTextAreaElement>;
  /**
   * Handler that is called when text in the input is selected. See MDN.
   */
  onSelect?: React.ReactEventHandler<HTMLTextAreaElement>;
  /**
   * Handler that is called when the input value is about to be modified. See MDN.
   */
  onBeforeInput?: React.FormEventHandler<HTMLTextAreaElement>;
  /**
   * Handler that is called when the input value is modified. See MDN.
   */
  onInput?: React.FormEventHandler<HTMLTextAreaElement>;
} & TextFieldProps;

function Textarea(
  props: TextareaProps,
  outerRef: React.Ref<HTMLTextAreaElement>,
) {
  const {
    label,
    secondaryLabel,
    validationState,
    isDisabled,
    isReadOnly,
    errorMessage,
    resize,
  } = props;

  const innerRef = React.useRef<HTMLTextAreaElement>(null);
  const errorMessageId = useId();
  const { labelProps, inputProps } = useTextField(
    {
      ...props,
      'aria-errormessage':
        props['aria-errormessage'] ?? errorMessage ? errorMessageId : undefined,
    },
    innerRef,
  );
  const { hoverProps, isHovered } = useHover(props);
  const { isFocusVisible, focusProps } = useFocusRing({
    isTextInput: true,
    autoFocus: props.autoFocus,
  });

  return (
    <Stack
      gap="2"
      className={classNames('plm-c-textarea', {
        'plm-c-textarea__hovered': props.UNSAFE__isHovered ?? isHovered,
        'plm-c-textarea__focused': props.UNSAFE__isFocused ?? isFocusVisible,
        'plm-c-textarea__invalid': isInvalid(validationState),
        'plm-c-textarea__disabled': isDisabled,
        'plm-c-textarea__readonly': isReadOnly,
      })}
    >
      {label && (
        <StackItem>
          <Label {...labelProps} secondary={secondaryLabel}>
            {label}
          </Label>
        </StackItem>
      )}
      <StackItem className="plm-c-textarea--field-row">
        <textarea
          {...mergeProps(inputProps, hoverProps, focusProps)}
          className={classNames('plm-c-textarea--field', {
            'plm-c-textarea--field__resize--both':
              resize === 'both' || resize === undefined,
            'plm-c-textarea--field__resize--none': resize === 'none',
            'plm-c-textarea--field__resize--horizontal':
              resize === 'horizontal',
            'plm-c-textarea--field__resize--vertical': resize === 'vertical',
          })}
          ref={assignRef(innerRef, outerRef)}
        />
      </StackItem>
      {errorMessage && (
        <StackItem
          id={errorMessageId}
          className="plm-c-textarea--tertiary-text"
        >
          {errorMessage}
        </StackItem>
      )}
    </Stack>
  );
}

Textarea.displayName = 'Plume__Textarea';

const _Textarea = React.forwardRef(Textarea);

export { _Textarea as Textarea };
