import cc from '@messagebird/componentized-classnames';
import { PolymorphicComponentProps } from 'react-polymorphic-box';
import './visually-hidden.css';

export interface VisuallyHiddenOwnProps {}

export type VisuallyHiddenProps<
  ElementType extends React.ElementType
> = PolymorphicComponentProps<ElementType, VisuallyHiddenOwnProps>;

/**
 * VisuallyHidden hides its children visually, while keeping content visible to screen readers.
 *
 * VisuallyHidden is a utility component that can be used to hide its children visually, while keeping them visible to screen readers and other assistive technology. This would typically be used when you want to take advantage of the behavior and semantics of a native element like a checkbox or radio button, but replace it with a custom styled element visually.
 */
export const VisuallyHidden = cc<VisuallyHiddenOwnProps>('div', {
  displayName: 'Plume__VisuallyHidden',
})({ className: 'plm-u-visually-hidden' });
