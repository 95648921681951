import React from 'react';
import mergeProps from 'merge-props';
import { useToggleState } from '@react-stately/toggle';
import { useCheckbox } from '@react-aria/checkbox';
import classNames from 'classnames';
import { useIsHovered } from '../../utils/useIsHovered';
import { useFocusVisible } from '../../utils/useFocusVisible';
import { assignRef } from '../../utils/assignRef';
import './checkbox.css';
import { Stack, StackItem } from '../../foundation/layout/Stack';
import { Text } from '../../foundation';

export type CheckboxProps = {
  /**
   * The label for the element.
   */
  children?: React.ReactNode;
  /**
   * Whether the element should be selected (uncontrolled).
   */
  defaultSelected?: boolean;
  /**
   * Whether the element should be selected (controlled).
   */
  isSelected?: boolean;
  /**
   * Handler that is called when the element's selection state changes.
   */
  onChange?: (isSelected: boolean) => void;
  /**
   * The value of the input element, used when submitting an HTML form. See [MDN](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdefvalue).
   */
  value?: string;
  /**
   * The name of the input element, used when submitting an HTML form. See [MDN](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#htmlattrdefname).
   */
  name?: string;
  /**
   * The element's unique identifier. See [MDN](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/id).
   */
  id?: string;

  /** Whether the input is disabled. */
  isDisabled?: boolean;
  /** Whether the input can be selected but not changed by the user. */
  isReadOnly?: boolean;

  /**
   * Whether the element should receive focus on render.
   * @default false
   */
  autoFocus?: boolean;
  /** Handler that is called when the element receives focus. */
  onFocus?: (e: React.FocusEvent) => void;
  /** Handler that is called when the element loses focus. */
  onBlur?: (e: React.FocusEvent) => void;
  /** Handler that is called when the element's focus status changes. */
  onFocusChange?: (isFocused: boolean) => void;

  /** Handler that is called when a key is pressed. */
  onKeyDown?: (e: React.KeyboardEvent) => void;
  /** Handler that is called when a key is released. */
  onKeyUp?: (e: React.KeyboardEvent) => void;

  /**
   * Identifies the element (or elements) whose contents or presence are controlled by the current element.
   */
  'aria-controls'?: string;
  /**
   * Defines a string value that labels the current element.
   */
  'aria-label'?: string;
  /**
   * Identifies the element (or elements) that labels the current element.
   */
  'aria-labelledby'?: string;

  /**
   * Identifies the element (or elements) that describes the object.
   */
  'aria-describedby'?: string;

  /**
   * Identifies the element (or elements) that provide a detailed, extended description for the object.
   */
  'aria-details'?: string;
  // https://www.w3.org/TR/wai-aria-1.2/#aria-errormessage
  /**
   * Identifies the element that provides an error message for the object.
   */
  'aria-errormessage'?: string;

  /**
   * Whether to exclude the element from the sequential tab order. If true,
   * the element will not be focusable via the keyboard by tabbing. This should
   * be avoided except in rare scenarios where an alternative means of accessing
   * the element or its functionality via the keyboard is available.
   */
  excludeFromTabOrder?: boolean;

  /**
   * This is to overwrite the default behaviour in focus handling
   *
   * **!!DON'T USE THIS PROPERTY!!** Documentation/Demonstration use only.
   */
  UNSAFE__focusVisible?: boolean;
};

function Checkbox(
  props: CheckboxProps,
  externalRef:
    | ((instance: HTMLInputElement | null) => void)
    | React.MutableRefObject<HTMLInputElement | null>
    | null,
) {
  const { isDisabled = false, children } = props;
  const { isFocusVisible, focusProps } = useFocusVisible(props);
  let { isHovered, hoverProps } = useIsHovered(props);

  const state = useToggleState(props);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const { inputProps } = useCheckbox(props, state, inputRef);

  return (
    <Stack
      orientation="horizontal"
      gap="2"
      as="label"
      {...mergeProps(hoverProps, {
        className: classNames('plm-c-checkbox', {
          'plm-c-checkbox--disabled': isDisabled,
          'plm-c-checkbox--selected': state.isSelected,
          'plm-c-checkbox--unselected': !state.isSelected,
          'plm-c-checkbox--focused': isFocusVisible,
          'plm-c-checkbox--hovered': isHovered,
        }),
        htmlFor: inputProps.id,
      })}
    >
      <input
        {...mergeProps(focusProps, inputProps, {
          className: 'plm-u-visually-hidden',
        })}
        ref={assignRef(inputRef, externalRef)}
      />

      <StackItem className="plm-c-checkbox__checkmark">
        <svg viewBox="0 0 16 16">
          <path
            className="plm-c-checkbox__checkpath"
            transform="translate(-4, -4)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M15 10L10.9730791 14 9 11.9526479"
            fill="none"
          />
        </svg>
      </StackItem>

      {children && (
        <StackItem as={Text} color="primary" size="sm">
          {children}
        </StackItem>
      )}
    </Stack>
  );
}

const _Checkbox = React.forwardRef(Checkbox);
export { _Checkbox as Checkbox };

Checkbox.displayName = 'Plume__Checkbox';
