import React, { ElementType } from 'react';
import { Box, PolymorphicComponentProps } from 'react-polymorphic-box';
import classNames from 'classnames';
import './divider.css';

const defaultElement = 'div';

export type DividerOwnProps = {
  /**
   * Whether to show show text in the divider
   */
  children?: React.ReactNode;
};

export type DividerProps<
  Element extends ElementType
> = PolymorphicComponentProps<Element, DividerOwnProps>;

/**
 * A divider that separates and distinguishes sections of content. This structure provides a visual boundary, for assistive technology it's revealed as a static, structural element.
 *
 * For example a `<Divider />` can be used to help visually divide two groups of menu items in a menu or to provide a horizontal rule between two sections of a page.
 */
export const Divider = <Element extends ElementType = typeof defaultElement>({
  className,
  children,
  ...props
}: DividerProps<Element>) => (
  <Box
    as={defaultElement}
    role="separator"
    className={classNames(
      'plm-c-divider',
      children && 'plm-c-divider__has-text',
      className,
    )}
    {...props}
  >
    {children}
  </Box>
);
