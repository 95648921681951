import React, { useRef, ReactNode, ElementType } from 'react';
import { PolymorphicComponentProps, Box } from 'react-polymorphic-box';
import mergeProps from 'merge-props';
import { FocusRing } from '@react-aria/focus';
import { useLink } from '@react-aria/link';
import classNames from 'classnames';
import { OwnTextProps } from '../../foundation/typography/Text';
import './link.css';

type AriaLinkProps = Parameters<typeof useLink>[0];

export type LinkOwnProps = Omit<AriaLinkProps, 'elementType'> & {
  /** The content to display in the link. */
  children: ReactNode;
} & OwnTextProps;

export type LinkProps<TElement extends ElementType> = PolymorphicComponentProps<
  TElement,
  LinkOwnProps
>;

const defaultElement = 'a';

export function Link<TElement extends ElementType = typeof defaultElement>({
  role,
  tabIndex,
  children,
  size = 'base',
  weight = 'normal',
  color = 'clickable',
  ...props
}: LinkProps<TElement>) {
  const ref = useRef<HTMLDivElement>(null);
  const { linkProps } = useLink(
    { ...props, elementType: props.as, children },
    ref,
  );

  return (
    <FocusRing focusRingClass="plm-c-link--focused">
      <Box
        {...mergeProps(
          {
            className: classNames(
              'plm-c-link',
              'plm-u-text',
              `plm-u-text__size-${size}`,
              `plm-u-text__weight-${weight}`,
              `plm-u-text__color-${color}`,
              props.isDisabled && 'plm-c-link--disabled',
            ),
          },
          linkProps,
          props,
        )}
        // Items can't be merged automatically with mergeProps
        role={role ?? linkProps.role}
        tabIndex={tabIndex ?? linkProps.tabIndex}
        ref={ref}
      >
        {children}
      </Box>
    </FocusRing>
  );
}

Link.defaultProps = { as: defaultElement };

Link.displayName = 'Plume__Link';
