import React from 'react';

export type ToggletipState = {
  open: () => void;
  close: () => void;
  closeWithPrio: () => void;
  toggle: () => void;
  toggleWithPrio: () => void;
  isOpen: boolean;
};

/**
 * State handling for toggletip.
 *
 * gives open, close and toggle functions in normal and higher prioritization. Higher prioritization is needed for cases like this:
 * 1. Hover opens the bubble
 * 2. Click on trigger, marks open with prioritization
 * 3. end hover, bubble should stay visible
 */
export function useToggletipState(props: {
  /** Overwrite dynamic isOpen behaviour. Handy for demos, tests and debugging  */
  isOpen?: boolean;
}): ToggletipState {
  const [isOpenPrio, setOpenPrio] = React.useState(false);
  const [isOpen, setOpen] = React.useState(false);

  const open = React.useCallback(() => {
    setOpen(true);
  }, []);

  const close = React.useCallback(() => {
    setOpen(false);
  }, []);

  const closeWithPrio = React.useCallback(() => {
    close();
    setOpenPrio(false);
  }, [close]);

  const toggle = React.useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const toggleWithPrio = React.useCallback(() => {
    toggle();
    setOpenPrio((prev) => !prev);
  }, [toggle]);

  return {
    open,
    close,
    closeWithPrio,
    toggle,
    toggleWithPrio,
    isOpen: props.isOpen ?? (isOpenPrio || isOpen),
  };
}
