import React from 'react';
import { Box, PolymorphicComponentProps } from 'react-polymorphic-box';
import { FocusScope } from '@react-aria/focus';
import { TabsContext, TabsLabelItem, Value } from './TabsContext';
import { TabsItem } from './TabsItem';
import { TabsButton } from './TabsButton';
import { useTabRegistry } from './useTabRegistry';

export interface TabsOwnProps {
  /** The default value (uncontrolled). */
  initialValue?: Value;
  /** The current value (controlled). */
  value?: Value;
  /** Handler that is called when the value changes. */
  onChange?: (val: string) => void;
  className?: string;
  /** The items. Only add a set of `<TabsItem />`. */
  children: React.ReactNode;
}

const defaultElement = 'div';

export type TabsProps<
  ElementType extends React.ElementType = typeof defaultElement
> = Omit<PolymorphicComponentProps<ElementType, {}>, keyof TabsOwnProps> &
  TabsOwnProps;

/**
 * Show and hide content based on user selection
 */
export const Tabs = <
  ElementType extends React.ElementType = typeof defaultElement
>({
  initialValue: userCustomInitialValue,
  value,
  children,
  onChange,
  ...props
}: TabsProps<ElementType>) => {
  const { initialValue, selfValue, setSelfValue, tabs } = useTabRegistry({
    value,
    userCustomInitialValue,
  });

  const clickHandler = (item: TabsLabelItem) => {
    if (item.disabled) return;
    setSelfValue(item.value);
    if (onChange) onChange(item.value);
  };

  return (
    <TabsContext.Provider value={initialValue}>
      <Box as={defaultElement} {...props}>
        <header className="plm-c-tabs" role="tablist">
          <FocusScope>
            {tabs.map((item) => (
              <TabsButton
                key={item.value}
                onPress={clickHandler}
                item={item}
                active={selfValue === item.value}
              />
            ))}
          </FocusScope>
        </header>
        {children}
      </Box>
    </TabsContext.Provider>
  );
};

Tabs.displayName = 'Plume__Tabs';

Tabs.Item = TabsItem;
