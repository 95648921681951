import React, { useEffect } from 'react';
import { useTabsContext, Value } from './TabsContext';

export interface TabsItemProps {
  /** The content showed in the tab bar. May contain any valid HTML, but make sure Icons get the right ARIA roles */
  label: React.ReactNode;
  /**
   * Internal identifier for the tab. Needs to be unique in this `<Tabs/>` but not in the app.
   * Will be emitted to the `onChange` handler and matched with `initialValue`/`value` of `<Tabs/>`
   */
  value: Value;
  /**
   * If true, item can't be activated
   * @default false
   */
  disabled?: boolean;
}

/**
 * Item in a `<Tabs/>` component.
 */
export const TabsItem: React.FunctionComponent<
  React.PropsWithChildren<TabsItemProps>
> = ({ children, value, label, disabled = false }) => {
  const { register, currentValue } = useTabsContext();
  const isActive = currentValue === value;

  useEffect(() => {
    if (!register) return;
    register({ value, label, disabled });
  }, [value, label, disabled, register]);

  return isActive ? (
    <div role="tabpanel" className="plm-c-tabs__tab-panel">
      {children}
    </div>
  ) : null;
};

TabsItem.displayName = 'Plume__TabsItem';
