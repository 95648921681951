import React from 'react';
import classNames from 'classnames';
import { Box, PolymorphicComponentProps } from 'react-polymorphic-box';
import { useButton } from '@react-aria/button';
import { FocusRing, useFocusManager } from '@react-aria/focus';
import mergeProps from 'merge-props';
import {
  FocusVisibleProps,
  useFocusVisible,
} from '../../utils/useFocusVisible';
import './button.css';
import { Icon } from '../Spinner/Icon';

type AriaButtonProps = Parameters<typeof useButton>[0];

export interface ButtonOwnProps
  extends Omit<AriaButtonProps, 'elementType'>,
    FocusVisibleProps {
  /**
   * @default 'medium'
   */
  size?: 'medium' | 'large' | 'small';

  /**
   * @default 'primary'
   */
  variant?: 'primary' | 'secondary' | 'negative';

  /**
   * @default false
   */
  isDisabled?: boolean;

  /**
   * @default false
   */
  isLoading?: boolean;
}

export type ButtonProps<
  ElementType extends React.ElementType
> = PolymorphicComponentProps<ElementType, ButtonOwnProps>;

const defaultElement = 'button';

export function Button<
  ElementType extends React.ElementType = typeof defaultElement
>(props: ButtonProps<ElementType>): JSX.Element {
  const {
    isDisabled,
    isLoading,
    size,
    className,
    children,
    variant,
    as = defaultElement,
  } = props;
  const ref = React.useRef<HTMLElement>();
  const { buttonProps, isPressed } = useButton(
    {
      ...props,
      isDisabled: isDisabled ?? isLoading,
      elementType: as,
    },
    ref as React.RefObject<HTMLElement>,
  );

  const focusManager = useFocusManager();
  const onKeyDown: React.KeyboardEventHandler = (e) => {
    switch (e.key) {
      case 'ArrowRight':
        focusManager?.focusNext({ wrap: true });
        break;
      case 'ArrowLeft':
        focusManager?.focusPrevious({ wrap: true });
        break;
    }
  };
  const { focusProps, isFocusVisible } = useFocusVisible(props);

  return (
    <FocusRing focusRingClass="plm-c-button--focused">
      <Box
        as={as}
        {...buttonProps}
        {...(mergeProps(focusProps, props) as typeof props)}
        onKeyDown={onKeyDown}
        className={classNames(className, 'plm-c-button', {
          'plm-c-button--medium': size === 'medium' || size === undefined,
          'plm-c-button--large': size === 'large',
          'plm-c-button--small': size === 'small',
          'plm-c-button--pressed': isPressed,
          'plm-c-button--disabled': isDisabled,
          'plm-c-button--primary':
            variant === 'primary' || variant === undefined,
          'plm-c-button--secondary': variant === 'secondary',
          'plm-c-button--negative': variant === 'negative',
          'plm-c-button--focused': isFocusVisible,
          'plm-c-button--loading': isLoading,
        })}
        ref={ref}
      >
        {isLoading && (
          <div className="plm-c-button__loading-spinner">
            <Icon />
          </div>
        )}
        <span className="plm-c-button__text">{children}</span>
      </Box>
    </FocusRing>
  );
}
