/**
 * @param rem - the relative value you are aiming for
 * @param base - base size, this can be a value in px or rem. If it's rem, it needs to be the "base", the font-size on the html element.
 */
export const getPxFromRem = (rem: string, base: string): number => {
  if (rem.endsWith('px')) return parseFloat(rem);

  if (base.endsWith('px')) {
    return parseFloat(rem) * parseFloat(base);
  }

  const baseFontSize = getComputedStyle(document.documentElement).fontSize;
  const baseSize = baseFontSize !== '' ? parseFloat(baseFontSize) : 16;

  return parseFloat(rem) * baseSize;
};
