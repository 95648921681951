import React from 'react';
import { useRadioGroup } from '@react-aria/radio';
import { useRadioGroupState } from '@react-stately/radio';
import { Stack, StackItem } from '../../foundation/layout/Stack';
import { Label } from '../Label';

type AriaRadioGroupProps = Parameters<typeof useRadioGroupState>[0];
type SegmentedControlState = ReturnType<typeof useRadioGroupState>;

export const SegmentedControlContext = React.createContext<SegmentedControlState | null>(
  null,
);

type EventProps = {
  /** Handler that is called when the value changes. */
  onChange?: (value: string) => void;
};

type AccessibilityProps = {
  /** The element's unique identifier. See [MDN](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/id).*/
  id?: string;
  /** Defines a string value that labels the current element.*/
  'aria-label'?: string;
  /** Identifies the element (or elements) that labels the current element.*/
  'aria-labelledby'?: string;
  /** Identifies the element (or elements) that describes the object.*/
  'aria-describedby'?: string;
  /** Identifies the element (or elements) that provide a detailed, extended description for the object.*/
  'aria-details'?: string;
  /** Identifies the element that provides an error message for the object.*/
  'aria-errormessage'?: string;
};

export type SegmentedControlsGroupProps = {
  /** The Radio(s) contained within the RadioGroup. */
  children: React.ReactNode;

  /** The content to display as the label. */
  label?: React.ReactNode;

  /** The name of the RadioGroup, used when submitting an HTML form. See [MDN](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#name_and_radio_buttons). */
  name?: string;

  /** The current value (controlled). */
  value?: string;

  /** The default value (uncontrolled). */
  defaultValue?: string;

  /**
   * Whether the input is disabled.
   * @default false
   */
  isDisabled?: boolean;
} & EventProps &
  AccessibilityProps;

/**
 * A Segmented Control consists of a set of radio buttons, and a label. Each radio
 * includes a label and a visual selection indicator. A single radio button
 * within the group can be selected at a time. Users may click or touch a radio
 * button to select it, or use the <code>Tab</code> key to navigate to the
 * group, the arrow keys to navigate within the group, and the
 * <code>Space</code> key to select an option.
 *
 * If you don't have an active state of an option take a look into the `ButtonGroup` component.
 */
export function SegmentedControlsGroup(
  props: SegmentedControlsGroupProps & AriaRadioGroupProps,
) {
  const { children, label } = props;
  const state = useRadioGroupState(props);
  const { radioGroupProps, labelProps } = useRadioGroup(
    { ...props, orientation: 'horizontal' },
    state,
  );

  return (
    <Stack {...radioGroupProps} orientation="vertical" gap="2">
      {label && (
        <StackItem>
          <Label {...labelProps}>{label}</Label>
        </StackItem>
      )}
      <StackItem>
        <div className="plm-c-button-group">
          <SegmentedControlContext.Provider value={state}>
            {children}
          </SegmentedControlContext.Provider>
        </div>
      </StackItem>
    </Stack>
  );
}

SegmentedControlsGroup.displayName = 'Plume__SegmentedControlsGroup';
